import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import { prevPage, nextPage, getTouches, debounce } from "./lib/readerCtrl";
import { decryptPage } from "./lib/canvasCtrl";
import LittleSpinner from "../../spinner/LittleSpinner";
import PopEnd from "./popup/PopEnd";
import useOnScreen from "../../../hooks/useOnScreen";

const Reader = ({
  pageNumber,
  setPageNumber,
  landscape,
  myBook,
  imageCorrection,
  toggleDirection,
  zoomFactor,
  id,
  chapterId,
  chapters,
  extract,
  gift,
  nextChapter,
  paperURL,
}) => {
  const [loadedPages, setLoadedPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [arrayOfPages, setArrayOfPages] = useState([]);
  const [xDown, setxDown] = useState(null);
  const [popEndVisible, setPopEndVisible] = useState(false);
  const [windowSize, setWindowSize] = useState();

  const refsById = useRef([]);

  //for transitionend
  const divRef = useRef();

  //wrapper
  const wrapperRef = useRef();

  //invisible element for popend with portrait
  const invisibleRef = useRef();

  const endPageVisibile = useOnScreen(
    landscape
      ? myBook.even
        ? refsById.current[myBook.pages - 1]
        : refsById.current[myBook.pages]
      : invisibleRef.current
  );

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  const bookEnd = () => {
    if (chapters[chapters.length - 1]._id === chapterId) {
      return true;
    } else {
      return false;
    }
  };

  const debouncedHandleResize = useMemo(() => debounce(handleResize, 300), []);

  //resize
  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //show popup at end
  useEffect(() => {
    setPopEndVisible(endPageVisibile);
  }, [endPageVisibile]);

  //create an array of page for render
  useEffect(() => {
    if (myBook.pages && myBook.even) {
      for (let i = 0; i < myBook.pages; i++) {
        setArrayOfPages((oldValues) => [
          ...oldValues.filter((value) => {
            return value !== i && value < i;
          }),
          i,
        ]);
      }
    }
    if (myBook.pages && !myBook.even && landscape) {
      for (let i = 0; i <= myBook.pages; i++) {
        setArrayOfPages((oldValues) => [
          ...oldValues.filter((value) => {
            return value !== i && value < i;
          }),
          i,
        ]);
      }
    }
    if (myBook.pages && !myBook.even && !landscape) {
      setArrayOfPages([]);
      for (let i = 1; i <= myBook.pages; i++) {
        setArrayOfPages((oldValues) => [
          ...oldValues.filter((value) => {
            return value !== i && value < i;
          }),
          i,
        ]);
      }
    }
  }, [myBook, chapterId, landscape]);

  const setPageNumberLoaded = (p) => {
    //setLoadedPages((oldValues) => [...oldValues.filter((val) => val !== p), p]);
    setLoadedPages((oldValues) => [...oldValues, p]);
  };

  const alreadyLoaded = (page) => {
    const isExist = loadedPages.find((p) => p === page);
    if (isExist) {
      return true;
    } else {
      return false;
    }
  };
  //load and decrypt image
  const load8Pages = useCallback(() => {
    if (id && chapterId && myBook && pageNumber !== undefined) {
      let lastPage = myBook.pages - 1 < 8 ? myBook.pages - 1 : 8;

      for (let i = 0; i <= lastPage; i++) {
        let canvas = refsById.current[pageNumber + i];
        if (!alreadyLoaded(pageNumber + i) && canvas && i < myBook.pages) {
          decryptPage(
            refsById.current[pageNumber + i],
            id,
            chapterId,
            pageNumber + i,
            myBook,
            imageCorrection.divWidth,
            imageCorrection.divHeight,
            setIsLoading,
            setPageNumberLoaded
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    loadedPages,
    id,
    myBook,
    chapterId,
    imageCorrection?.divHeight,
    imageCorrection?.divWidth,
  ]);

  //clear array of loaded pages if portrait/landscape change
  useEffect(() => {
    if (myBook.chapterId === chapterId) {
      setLoadedPages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape, windowSize, myBook, chapterId]);

  ////////////     !!!!!!!!!!!!!!!!!! IMOPORTANT DE VERIFIER SI OK DEBUT CHAPTER

  //load pages at begin (page 0 ) new chapter
  /*useEffect(() => {
    if (landscape && loadedPages.length < 7) {
      load8Pages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPages, arrayOfPages, myBook.pages]);*/

  //////////////////////////////// TEST ////////////////////////////////////////////
  //load pages at begin (page 0 ) new chapter
  useEffect(() => {
    if (loadedPages.length === 0) {
      if (landscape && myBook.pages > 0) {
        load8Pages();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landscape, myBook, loadedPages.length]);

  //////////////////////////////////////////////////////////////////////////////////////

  //load pages on scroll
  useEffect(() => {
    if (!landscape) {
      load8Pages();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, landscape, windowSize, myBook, chapterId]);

  //load pages on transitionend if landscape
  useEffect(() => {
    const el = divRef.current;
    if (el) {
      el.addEventListener("transitionend", load8Pages);

      return () => el.removeEventListener("transitionend", load8Pages);
    }
  });

  //return peer number if total is odd
  const totalOfPageIfImpair = () => {
    if (myBook?.pages % 2 === 0) {
      return myBook?.pages;
    } else {
      return myBook?.pages + 1;
    }
  };

  function rightclick(e) {
    if ((e.which && e.which === 3) || (e.button && e.button === 2)) {
      alert("Images protégées par Copyright"); // true or false, you can trap right click here by if comparison
    }
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    setxDown(firstTouch.clientX);
  }

  //detect swip right or left to change page on landscape
  function handleTouchMove(evt) {
    if (zoomFactor === 1 && landscape) {
      if (!xDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var xDiff = xDown - xUp;

      /*most significant*/

      if (xDiff > 0) {
        /* right swipe */
        evt.preventDefault();
        myBook?.readingDirection === "europe" &&
          nextPage(pageNumber, setPageNumber, myBook);
        myBook?.readingDirection === "original" &&
          prevPage(pageNumber, setPageNumber);
      } else {
        evt.preventDefault();
        myBook.readingDirection === "europe" &&
          prevPage(pageNumber, setPageNumber);
        myBook?.readingDirection === "original" &&
          nextPage(pageNumber, setPageNumber, myBook);
      }
      setxDown(null);
    }
  }

  //swipe event to change page
  useEffect(() => {
    if (landscape) {
      const element = wrapperRef.current;
      element.addEventListener("touchstart", handleTouchStart, false);
      element.addEventListener("touchmove", handleTouchMove, false);
      return () => {
        element.removeEventListener("touchstart", handleTouchStart, false);
        element.removeEventListener("touchmove", handleTouchMove, false);
      };
    }
  });

  return (
    <Wrapper ref={wrapperRef} landscape={landscape}>
      {myBook.chapterId === chapterId && (
        <Div
          ref={divRef}
          maxWidth={windowSize?.width}
          maxHeight={windowSize?.height}
          pageNumber={pageNumber}
          landscape={landscape}
          totalPage={totalOfPageIfImpair}
          toggleDirection={toggleDirection}
          zoomFactor={zoomFactor}
        >
          {arrayOfPages.length > 0 &&
            arrayOfPages.map((p, i) => (
              <HoriContainer
                key={p}
                zoomFactor={zoomFactor}
                width={landscape ? windowSize.width / 2 : windowSize.width}
                height={imageCorrection?.divHeight}
                justifyContent={
                  landscape
                    ? myBook?.readingDirection === "original"
                      ? i % 2 === 0
                        ? "start"
                        : "end"
                      : i % 2 === 0
                      ? "end"
                      : "start"
                    : "center"
                }
                landscape={landscape}
                onClick={
                  i % 2 === 0
                    ? landscape
                      ? () => prevPage(pageNumber, setPageNumber)
                      : null
                    : landscape
                    ? () => nextPage(pageNumber, setPageNumber, myBook)
                    : null
                }
              >
                {
                  <Container
                    zoomFactor={zoomFactor}
                    width={imageCorrection?.divWidth}
                    height={imageCorrection?.divHeight}
                    onMouseDown={rightclick}
                  >
                    {
                      <Canvas
                        id={p}
                        ref={(el) => (refsById.current[p] = el)}
                        height={
                          myBook?.idty.length > 0
                            ? imageCorrection?.height
                            : imageCorrection?.divHeight
                        }
                        width={
                          myBook?.idty.length > 0
                            ? imageCorrection?.width
                            : imageCorrection?.divWidth
                        }
                      />
                    }
                  </Container>
                }
              </HoriContainer>
            ))}
          {popEndVisible && pageNumber !== 0 && (
            <PopEnd
              bookEnd={bookEnd}
              visible={popEndVisible}
              close={() => setPopEndVisible(false)}
              extract={extract}
              nextChapter={nextChapter}
              myBook={myBook}
              gift={gift}
              paperURL={paperURL}
            />
          )}
          {<Test>{isLoading && <LittleSpinner />}</Test>}
          {<BottomInvisibleElement ref={invisibleRef} />}
        </Div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.landscape ? "flex" : "block")};
  align-items: ${(props) => (props.landscape ? "center" : "unset")};
`;

const Div = styled.div`
  position: absolute;
  display: flex;
  align-items: ${(prop) => (prop.landscape ? "unset" : "unset")};
  justify-content: center;
  width: ${(prop) =>
    prop.landscape
      ? prop.maxWidth * Math.ceil(prop.totalOfPageIfImpair / 2)
      : Math.round(prop.maxWidth)}px;

  flex-direction: ${(prop) =>
    prop.landscape ? prop.toggleDirection : "column"};

  right: ${(prop) =>
    prop.landscape && prop.toggleDirection() === "row-reverse"
      ? -prop.maxWidth * (prop.pageNumber / 2) + "px"
      : "unset"};
  left: ${(prop) =>
    prop.landscape && prop.toggleDirection() === "row"
      ? -prop.maxWidth * (prop.pageNumber / 2) + "px"
      : "unset"};
  transition: all 0.3s ease-in-out;
`;

const Container = styled.div`
  width: ${(prop) => prop.width}px;
  height: ${(prop) => prop.height}px;
  overflow: hidden;
`;

const HoriContainer = styled.div`
  width: ${(prop) => prop.width}px;
  height: ${(prop) => prop.height}px;
  display: flex;
  justify-content: ${(prop) => prop.justifyContent};
`;

const Canvas = styled.canvas`
  display: relative;
  top: 0;
  left: 0;
  height: ${(prop) => prop.height}px;
  width: ${(prop) => prop.width}px;
`;

const Test = styled.div`
  position: fixed;
  bottom: 15px;
  left: 20px;
  color: white;
  z-index: 1000;
`;

const BottomInvisibleElement = styled.div`
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export default Reader;
