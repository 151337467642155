//filter by active product
const activeProducts = (products) => {
  if (products && products.length > 0) {
    const isActive = products.filter((product) => product.active === true);
    if (isActive && isActive.length > 0) {
      return isActive;
    } else {
      return [];
    }
  }
};
//sort by releaseDate all products
export const sortByReleaseDate = (array) => {
  if (array && array.length > 0) {
    let sort = [...activeProducts(array)].sort(
      (a, b) =>
        //new Date(b.lastModify).getTime() - new Date(a.lastModify).getTime()
        Number(b.lastModify) - Number(a.lastModify)
    );
    return sort;
  }
};

//sort by alphabet order
export const sortByOrder = (array) => {
  let sort = [...activeProducts(array)].sort((a, b) =>
    a.frenchTitle.localeCompare(b.frenchTitle)
  );

  return sort;
};

//sort by volume number
export const sortByVolume = (array) => {
  let sort = [...activeProducts(array)].sort(
    (a, b) => Number(a.volume) - Number(b.volume)
  );
  if (sort.length > 0) {
    return sort;
  } else {
    return [];
  }
};

//sort by category / release date / cat active
export const byCategory = (array, cat) => {
  if (array && array.length > 0) {
    const filterByCat = activeProducts(array).filter(
      (product) => product.category === cat.name && cat.active
    );
    if (filterByCat) {
      return sortByReleaseDate(filterByCat);
    }
  }
};

export const sortByLikes = (array) => {
  if (array && array.length > 0) {
    const filter = array.filter((product) => product.totalLikes);
    if (filter && filter.length > 0) {
      let sort = [...activeProducts(filter)].sort(
        (a, b) => calculLikes(b) - calculLikes(a)
      );

      if (sort.length > 0) {
        return sort;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
};

export const calculLikes = (product) => {
  if (product && product.totalLikes) {
    return product.totalLikes;
  } else {
    return 0;
  }
};

export const filterDigitalExclu = (products, category, setState) => {
  if (products && products.length > 0) {
    let exclus = products.filter(
      (p) => p.digitalExclusive === true && p.active
    );
    setState(sortByReleaseDate(filterByActiveCategory(exclus, category)));
  }
};

export const filterByActiveCategory = (products, category) => {
  if (products && products.length > 0 && category && category.length > 0) {
    let catActiveArray = [];
    category.forEach((cat) => {
      if (cat.active) {
        const catProducts = products.filter(
          (product) => product.category === cat.name
        );
        catActiveArray.push(...catProducts);
      }
    });
    return catActiveArray;
  }
};

export const isOneShot = (product) => {
  if (product?.volumes.length === 1 && product?.ended) {
    return true;
  } else {
    return false;
  }
};
