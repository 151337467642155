import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Sticker from "../../components/marketplace/Sticker";
import ExcluSlider from "../../components/marketplace/home/excluSlider/ExcluSlider";
import Category from "../../components/marketplace/home/category/index";
import Slider from "../../components/marketplace/library/Slider/Slider";
import Carousel from "../../components/marketplace/carousel";
import { useCensored } from "./categoryPage/controllers/stateCtrl";
import {
  sortByReleaseDate,
  sortByLikes,
  sortByOrder,
  filterDigitalExclu,
  byCategory,
  filterByActiveCategory,
} from "./categoryPage/controllers/algoCtrl";
import {
  useProductsState,
  useCategoryState,
  useCarouselState,
} from "./categoryPage/controllers/stateCtrl";
import IconComponent from "../../components/marketplace/home/iconComponent";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const Home = () => {
  const [digitalExclu, setDigitalExclu] = useState([]);
  const [activeCategoryProducts, setActiveCategoryProducts] = useState([]);

  const isCensored = useCensored(useSelector);

  const products = useProductsState(useSelector);
  const category = useCategoryState(useSelector);
  const carousel = useCarouselState(useSelector);

  useEffect(() => {
    if (products && category && products.length > 0 && category.length > 0) {
      filterDigitalExclu(products, category, setDigitalExclu);
      setActiveCategoryProducts(filterByActiveCategory(products, category));
    }
  }, [products, category]);

  return (
    <Wrapper>
      <Helmet>
        <title>Mangaebook : lecture manga en ligne.</title>
        <meta
          id="og-title"
          property="og:title"
          content={`Lisez vos mangas au format ebook en ligne.`}
        />
        <meta
          name="title"
          content={`Lisez vos mangas au format ebook en ligne.`}
        />
        <meta
          name="description"
          content="Yaoi - Boy's Love, Hentai et exclusités numériques en lecture en ligne et téléchargement (epub) des collections 'Taifu' et 'Nihoniba'."
        />
        <meta
          property="og:description"
          content="Yaoi - Boy's Love, Hentai et exclusités numériques en lecture en ligne et téléchargement (epub) des collections 'Taifu' et 'Nihoniba'."
        />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com"
        ></meta>
        <meta property="og:url" content="https://mangaebook.com/" />
        <meta
          id="og-image"
          property="og:image"
          content="http://mangaebook.com/og-img.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://mangaebook.com/og-img.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <H1 className="segoesc">
        <Link to="/">MangaeBook /</Link>Accueil
      </H1>
      <Category />
      {!isCensored && carousel && carousel.length > 0 && (
        <Carousel carousel={carousel} />
      )}
      <IconComponent />
      {activeCategoryProducts && activeCategoryProducts.length > 0 && (
        <>
          {digitalExclu && (
            <section>
              <ExcluSlider products={digitalExclu} />
            </section>
          )}
          {sortByReleaseDate(activeCategoryProducts).length > 0 && (
            <>
              <Sticker
                title="à decouvrir !"
                marginTop="60"
                center={true.toString()}
              />
              <Container>
                <Slider
                  title="nouveautés"
                  array={sortByReleaseDate(activeCategoryProducts)}
                  article={true.toString()}
                />
              </Container>
            </>
          )}
          {sortByLikes(activeCategoryProducts).length > 0 && (
            <Container>
              <Slider
                title="au top !"
                array={sortByLikes(activeCategoryProducts)}
                article={true.toString()}
              />
            </Container>
          )}
          <Sticker
            title="Par univers"
            marginTop="60"
            center={true.toString()}
          />
          {category &&
            products &&
            category.length > 0 &&
            products.length > 0 &&
            category.map(
              (cat) =>
                byCategory(products, cat)?.length > 0 && (
                  <Container key={cat._id}>
                    <Slider
                      title={cat.name}
                      array={sortByOrder(byCategory(products, cat))}
                      article={true.toString()}
                    />
                  </Container>
                )
            )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: 40px;
`;

const H1 = styled.h1`
  position: absolute;
  font-size: 14px;
  top: 70px;
  left: 30px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

export default Home;
