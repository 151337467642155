import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../components/admin/input/Text";
import styled from "styled-components";
import { inputDate } from "../../../lib/adminControler/validateProduct/validate";
import {
  sortByQuantity,
  validateSearch,
  sortByCommand,
  ttcPrice,
  returnVolume,
  returnProduct,
  returnUserMail,
  returnTotalttc,
} from "./exportCtrl";
import { CSVLink } from "react-csv";
import {
  useProductsState,
  useVolumesState,
  useTvaState,
  useUsersState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";
import { Link } from "react-router-dom";

const Export = () => {
  const [startDate, setStartDate] = useState(inputDate(new Date()));
  const [endDate, setEndDate] = useState(inputDate(new Date()));
  const [purchases, setPurchases] = useState([]);
  const [sortPurchases, setSortPurchases] = useState([]);
  const [xlsArray, setXlsArray] = useState([]);
  const [xlsArray2, setXlsArray2] = useState([]);
  const [hide, setHide] = useState(false);
  const [commands, setCommands] = useState([]);
  const [xlsCommandsArray, setXlsCommandsArray] = useState([]);

  const products = useProductsState(useSelector);
  const volumes = useVolumesState(useSelector);
  const tva = useTvaState(useSelector);
  const users = useUsersState(useSelector);

  const dispatch = useDispatch();

  const toggleHide = () => {
    setHide(!hide);
  };

  useEffect(() => {
    if (purchases && purchases.length > 0) {
      sortByQuantity(purchases, setSortPurchases);
      setXlsArray(tableToXls(purchases));
      sortByCommand(purchases, setCommands);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchases]);

  useEffect(() => {
    if (sortPurchases && sortPurchases.length > 0) {
      setXlsArray2(tableToXls(sortPurchases, true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortPurchases]);

  useEffect(() => {
    if (commands && commands.length > 0) {
      setXlsCommandsArray(commandsToXls(commands));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commands]);

  const returnTva = (name) => {
    const findTva = tva.find((t) => t.name === name);
    if (findTva) {
      return findTva.rate;
    }
  };

  const returnChapter = (volumeId, chapterId) => {
    const vol = returnVolume(volumeId, volumes);
    if (vol) {
      const findChapter = vol.chapters.find(
        (chapter) => chapter._id === chapterId
      );
      if (findChapter) {
        return findChapter;
      }
    }
  };

  const tableToXls = (state, isQuantity = false) => {
    if (state && state.length > 0) {
      const data = state.map((purchase) => {
        const product = returnProduct(purchase.volumeId, products, volumes);
        const chapter = returnChapter(purchase.volumeId, purchase.chapterId);
        const volume = returnVolume(purchase.volumeId, volumes);

        //if (product && volume) {
        return {
          Titre_français: product?.frenchTitle
            ? product.frenchTitle
            : "titre inconnu",
          Titre_original: product?.originalTitle
            ? product.originalTitle
            : "titre inconnu",
          Volume: volume?.volume ? volume.volume : "one-shot",
          Chapitre: purchase.chapterId ? chapter.chapterNumber : "complet",
          Mise_en_vente: purchase.chapterId
            ? new Date(
                returnChapter(purchase.volumeId, purchase.chapterId).releaseDate
              ).toLocaleDateString("fr")
            : volume?.volumeReleaseDate
            ? new Date(volume.volumeReleaseDate).toLocaleDateString("fr")
            : "date inconnu",
          Catégorie: product?.category ? product.category : "catégorie inconnu",
          Couleur: volume
            ? volume.color
              ? "oui"
              : "non"
            : "information inconnu",
          Pages: purchase.chapterId
            ? chapter.totalOfPage
            : volume?.totalOfPage
            ? volume.totalOfPage
            : "nombre inconnu",
          exclus_numérique: volume?.digitalExclusive ? "oui" : "non",
          Edition_originale: product?.originalPublisher
            ? product.originalPublisher
            : "éditeur inconnu",
          Auteur: product?.author ? product.author[0] : "auteur inconnu",
          Illustrateur: product?.illustrator
            ? product.illustrator
            : "illustrateur inconnu",
          Traducteur: volume?.traductor
            ? volume.traductor
            : "traducteur inconnu",
          Agent: volume?.agent ? volume.agent : "agent inconnu",
          Prix_TTC: !isQuantity
            ? ttcPrice(tva, purchase.price, purchase.tva)
            : "---",
          Prix_HT: purchase.price,
          TVA: returnTva(purchase.tva),
          ISBN: purchase.chapterId ? chapter.ISBN : volume?.ISBN,
          Date_achat: !isQuantity
            ? new Date(purchase.purchaseDate).toLocaleDateString("fr")
            : "période",
          Royalties_achat: purchase.chapterId
            ? chapter.royaltyBuyingPrice
            : volume?.royaltyBuyingPrice
            ? volume.royaltyBuyingPrice
            : "0",
          Royalties_revenu: purchase.chapterId
            ? chapter.royaltyRevenu
            : volume?.royaltyRevenu
            ? volume.royaltyRevenu
            : "0",
          likes: volume?.likes ? volume.likes.length : "inconnu",
          quantité: purchase.quantity ? purchase.quantity : 1,
        };
        //  }
      });
      return data;
    }
  };

  const commandsToXls = (state) => {
    if (state && state.length > 0) {
      const data = state.map((command) => {
        return {
          numéro_de_comande: command.orderId ? command.orderId : "inconnu",
          utilisateur: returnUserMail(command.userId, users),
          date_de_commande: new Date(command.purchaseDate).toLocaleDateString(
            "fr"
          ),
          prix_HT: command.totalHt ? command.totalHt / 100 : "introuvable",
          prix_TTC: isNaN(returnTotalttc(command.list, tva))
            ? "---"
            : returnTotalttc(command.list, tva),
        };
      });

      return data;
    }
  };

  return (
    <Wrapper>
      <H1>exportation de données au format exel (choix d'une période)</H1>
      <form>
        <Text
          type="date"
          name="startDate"
          onchange={(e) => setStartDate(e.target.value)}
          title="Date de début de recherche"
          value={startDate}
        />
        <Text
          type="date"
          name="endDate"
          onchange={(e) => setEndDate(e.target.value)}
          title="Date de fin de recherche"
          value={endDate}
        />
        <button
          type="button"
          onClick={() =>
            validateSearch(startDate, endDate, dispatch, setPurchases)
          }
        >
          rechercher
        </button>
      </form>
      <button type="button" onClick={() => toggleHide()}>
        Tableaux {hide ? " cachés" : " visibles"}
      </button>
      {!hide && (
        <Table>
          <Caption>
            Tableau unitaire des ventes entre le{" "}
            {new Date(startDate).toLocaleDateString("fr")} et le{" "}
            {new Date(endDate).toLocaleDateString("fr")}
            {purchases.length > 0 && (
              <p>{purchases.length} article(s) vendus.</p>
            )}
          </Caption>
          <thead>
            <tr>
              <Th scope="col">Titre fr.</Th>
              <Th scope="col">Titre or.</Th>
              <Th scope="col">Volume</Th>
              <Th scope="col">Chapitre</Th>
              <Th scope="col">Mise en vente</Th>
              <Th scope="col">Catégorie</Th>
              <Th scope="col">Couleur</Th>
              <Th scope="col">Pages</Th>
              <Th scope="col">exclus num.</Th>
              <Th scope="col">Edition or.</Th>
              <Th scope="col">Auteur</Th>
              <Th scope="col">Illustrateur</Th>
              <Th scope="col">Traducteur</Th>
              <Th scope="col">Agent</Th>
              <Th scope="col">Prix TTC</Th>
              <Th scope="col">Prix HT</Th>
              <Th scope="col">TVA</Th>
              <Th scope="col">ISBN</Th>
              <Th scope="col">Date achat</Th>
              <Th scope="col">Royalt. achat</Th>
              <Th scope="col">Royalt. revenu</Th>
              <Th scope="col">likes</Th>
            </tr>
          </thead>

          {xlsArray &&
            xlsArray.map((purchase, i) => (
              <tbody key={i}>
                <tr>
                  <Td>{purchase.Titre_français}</Td>
                  <Td>{purchase.Titre_original}</Td>
                  <Td>{purchase.Volume}</Td>
                  <Td>{purchase.Chapitre}</Td>
                  <Td>{purchase.Mise_en_vente}</Td>
                  <Td>{purchase.Catégorie}</Td>
                  <Td>{purchase.Couleur}</Td>
                  <Td>{purchase.Pages}</Td>
                  <Td>{purchase.exclus_numérique}</Td>
                  <Td>{purchase.Edition_originale}</Td>
                  <Td>{purchase.Auteur}</Td>
                  <Td>{purchase.Illustrateur}</Td>
                  <Td>{purchase.Traducteur}</Td>
                  <Td>{purchase.Agent}</Td>
                  <Td>{purchase.Prix_TTC}</Td>
                  <Td>{purchase.Prix_HT / 100}</Td>
                  <Td>{purchase.TVA}</Td>
                  <Td>{purchase.ISBN}</Td>
                  <Td>{purchase.Date_achat}</Td>
                  <Td>
                    {purchase.Royalties_achat !== "0"
                      ? purchase.Royalties_achat
                      : 0}
                  </Td>
                  <Td>
                    {purchase.Royalties_revenu !== "0"
                      ? purchase.Royalties_revenu
                      : 0}
                  </Td>
                  <Td>{purchase.likes}</Td>
                </tr>
              </tbody>
            ))}
        </Table>
      )}
      {xlsArray && xlsArray.length > 0 && (
        <Button type="button">
          <CSVLink data={xlsArray} separator={";"}>
            télécharger au format Excel le tableau unitaire
          </CSVLink>
        </Button>
      )}

      {!hide && (
        <Table>
          <Caption>
            Tableau groupé des ventes entre le{" "}
            {new Date(startDate).toLocaleDateString("fr")} et le{" "}
            {new Date(endDate).toLocaleDateString("fr")}
          </Caption>
          <thead>
            <tr>
              <Th scope="col">Titre fr.</Th>
              <Th scope="col">Titre or.</Th>
              <Th scope="col">Volume</Th>
              <Th scope="col">Chapitre</Th>
              <Th scope="col">Mise en vente</Th>
              <Th scope="col">Catégorie</Th>
              <Th scope="col">Couleur</Th>
              <Th scope="col">Pages</Th>
              <Th scope="col">exclus num.</Th>
              <Th scope="col">Edition or.</Th>
              <Th scope="col">Auteur</Th>
              <Th scope="col">Illustrateur</Th>
              <Th scope="col">Traducteur</Th>
              <Th scope="col">Agent</Th>
              <Th scope="col">ventes</Th>
              <Th scope="col">ISBN</Th>
              <Th scope="col">Royalt. achat</Th>
              <Th scope="col">Royalt. revenu</Th>
              <Th scope="col">likes</Th>
            </tr>
          </thead>
          <tbody>
            {xlsArray2 &&
              xlsArray2.map((purchase, i) => (
                <tr key={i}>
                  <Td>{purchase.Titre_français}</Td>
                  <Td>{purchase.Titre_original}</Td>
                  <Td>{purchase.Volume}</Td>
                  <Td>{purchase.Chapitre}</Td>
                  <Td>{purchase.Mise_en_vente}</Td>
                  <Td>{purchase.Catégorie}</Td>
                  <Td>{purchase.Couleur}</Td>
                  <Td>{purchase.Pages}</Td>
                  <Td>{purchase.exclus_numérique}</Td>
                  <Td>{purchase.Edition_originale}</Td>
                  <Td>{purchase.Auteur}</Td>
                  <Td>{purchase.Illustrateur}</Td>
                  <Td>{purchase.Traducteur}</Td>
                  <Td>{purchase.Agent}</Td>
                  <Td>{purchase.quantité}</Td>
                  <Td>{purchase.ISBN}</Td>
                  <Td>
                    {purchase.Royalties_achat !== "0"
                      ? purchase.Royalties_achat
                      : 0}
                  </Td>
                  <Td>
                    {purchase.Royalties_revenu !== "0"
                      ? purchase.Royalties_revenu
                      : 0}
                  </Td>
                  <Td>{purchase.likes}</Td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      {xlsArray2 && xlsArray2.length > 0 && (
        <Button type="button">
          <CSVLink data={xlsArray2} separator={";"}>
            Télécharger au format Excel le tableau groupé
          </CSVLink>
        </Button>
      )}
      {!hide && (
        <Table>
          <Caption>
            Tableau des commandes entre le{" "}
            {new Date(startDate).toLocaleDateString("fr")} et le{" "}
            {new Date(endDate).toLocaleDateString("fr")}
            (!!! valable que depuis le 25/09/2024!!!)
            <p>
              (Toutes commandes passées avant cette date ne seront pas visibles)
            </p>
          </Caption>
          <thead>
            <tr>
              <Th scope="col">Numéro de commande</Th>
              <Th scope="col">Utilisateur</Th>
              <Th scope="col">Date de commande</Th>
              <Th scope="col">Prix HT</Th>
              <Th scope="col">Prix TTC</Th>
              <Th scope="col">détail</Th>
            </tr>
          </thead>
          <tbody>
            {xlsCommandsArray &&
              xlsCommandsArray.map((command, i) => (
                <tr
                  key={
                    command.numéro_de_comande
                      ? command.numéro_de_comande + i
                      : i
                  }
                >
                  <Td>{command.numéro_de_comande}</Td>
                  <Td>{command.utilisateur}</Td>
                  <Td>{command.date_de_commande}</Td>
                  <Td>{command.prix_HT}</Td>
                  <Td>{command.prix_TTC}</Td>
                  <Td>
                    {command.numéro_de_comande !== "inconnu" ? (
                      <Link to={`command/${command.numéro_de_comande}`}>
                        plus
                      </Link>
                    ) : (
                      "pas de numéro de commande"
                    )}
                  </Td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <Button type="button">
        <CSVLink data={xlsCommandsArray} separator={";"}>
          télécharger au format Excel le tableau unitaire
        </CSVLink>
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100vh - 100px);
  z-index: 100;
  padding: 2em;
  overflow: scroll;
`;

const H1 = styled.h1`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Table = styled.table`
  min-width: 2000px;
  margin-top: 50px;
  height: 50px;
  border-collapse: collapse;
  border: 3px solid black;
  text-align: center;
`;

const Caption = styled.caption`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 5px;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

const Button = styled.button`
  text-transform: uppercase;
  padding: 10px;
  margin-top: 10px;
`;

export default Export;
