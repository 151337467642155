import { Request, Constant } from "../../../service/index";

const {
  PRODUCTS,
  VOLUME,
  CHAPTER,
  ALLPRODUCTS,
  CATEGORY,
  TVA,
  NOTIFICATIONS,
  ADMIN,
  SIGNUP,
  LOGIN,
  LOGOUT,
  VERIFY,
  USER,
  USERSIGNUP,
  USERLOGIN,
  USERVERIFY,
  ACTIVATEACCOUNT,
  FORGOTPASSWORD,
  RESETPASSWORD,
  KEY,
  BOOKS,
  REMOVELIBRARY,
  LIKE_PRODUCT,
  CART,
  DOWNLOAD,
  NETWORK,
  CAROUSEL,
} = Constant;

export const actions = {
  FETCH_PENDING: "FETCH_PENDING",
  FETCH_PRODUCT: "FETCH_PRODUCT",
  FETCH_ALLPRODUCT: "FETCH_ALLPRODUCT",
  FETCH_ALLVOLUME: "FETCH_ALLVOLUME",
  FETCH_CATEGORY: "FETCH_CATEGORY",
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
  FETCH_ADMIN: "FETCH_ADMIN",
  FETCH_USER: "FETCH_USER",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_ERROR: "FETCH_ERROR",
  FETCH_MESSAGE: "FETCH_MESSAGE",
  FETCH_TVA: "FETCH_TVA",
  FETCH_ADMINCONNECTED: "FETCH_ADMINCONNECTED",
  FETCH_ADMINDISCONNECTED: "FETCH_ADMINDISCONNECTED",
  FETCH_USERCONNECTED: "FETCH_USERCONNECTED",
  FETCH_USERDISCONNECTED: "FETCH_USERDISCONNECTED",
  FETCH_KEY: "FETCH_KEY",
  ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
  REMOVE_ARTICLE_FROM_CART: "REMOVE_ARTICLE_FROM_CART",
  LOAD_CARTSTORAGE: "LOAD_CARTSTORAGE",
  CLEAR_CART: "CLEAR_CART",
  FETCH_CENSORSHIP: "FETCH_CENSORSHIP",
  FETCH_NETWORK: "FETCH_NETWORK",
  FETCH_POPUPMESSAGE: "FETCH_POPUPMESSAGE",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  FETCH_CAROUSEL: "FETCH_CAROUSEL",
};

//create actions
export function fetchPending() {
  return {
    type: actions.FETCH_PENDING,
  };
}

export function fetchSuccess() {
  return {
    type: actions.FETCH_SUCCESS,
  };
}

export function fetchError(err) {
  return {
    type: actions.FETCH_ERROR,
    payload: { error: err },
  };
}

export function fetchNetwork(result) {
  return {
    type: actions.FETCH_NETWORK,
    payload: { networks: result },
  };
}

export function fetchMessage(message) {
  return {
    type: actions.FETCH_MESSAGE,
    payload: { message: message.message },
  };
}

export function fetchPopupMessage(message) {
  return {
    type: actions.FETCH_POPUPMESSAGE,
    payload: message,
  };
}

export function fetchDeletePopupMessage() {
  return {
    type: actions.DELETE_MESSAGE,
  };
}

export function fetchProduct(results) {
  return {
    type: actions.FETCH_PRODUCT,
    payload: { products: results },
  };
}

export function fetchAllProduct(results) {
  return {
    type: actions.FETCH_ALLPRODUCT,
    payload: { products: results },
  };
}

export function fetchAllVolume(results) {
  return {
    type: actions.FETCH_ALLVOLUME,
    payload: { volumes: results },
  };
}

export function fetchCategory(results) {
  return {
    type: actions.FETCH_CATEGORY,
    payload: { category: results },
  };
}

export function fetchTva(results) {
  return {
    type: actions.FETCH_TVA,
    payload: { tva: results },
  };
}

export function fetchKey(results) {
  return {
    type: actions.FETCH_KEY,
    payload: { key: results },
  };
}

export function fetchNotif(results, userId) {
  return {
    type: actions.FETCH_NOTIFICATIONS,
    payload: { userId, notifications: results },
  };
}

export function fetchCarousel(results) {
  return {
    type: actions.FETCH_CAROUSEL,
    payload: { bannieres: results },
  };
}

export function removeFromNotifs(id, userId) {
  return {
    type: actions.REMOVE_NOTIFICATION,
    payload: { id, userId },
  };
}

export function fetchAdmin(results) {
  return {
    type: actions.FETCH_ADMIN,
    payload: { admin: results },
  };
}

export function fetchAdminConnected(results) {
  return {
    type: actions.FETCH_ADMINCONNECTED,
    payload: {
      adminPseudo: results.adminPseudo,
      superAdmin: results.superAdmin,
      token: results.token,
    },
  };
}

export function fetchAdminDisconnected() {
  return {
    type: actions.FETCH_ADMINDISCONNECTED,
  };
}

export function fetchUser(results) {
  return {
    type: actions.FETCH_USER,
    payload: { users: results },
  };
}

export function fetchUserConnected(results) {
  return {
    type: actions.FETCH_USERCONNECTED,
    payload: {
      userId: results.userId,
      userPseudo: results.userPseudo,
      userEmail: results.userEmail,
      date: results.userDate,
      userEbooks: results.userEbooks,
      google: results.google,
      newsletter: results.newsletter,
      token: results.token,
    },
  };
}

export function fetchUserDisconnected() {
  return {
    type: actions.FETCH_USERDISCONNECTED,
  };
}

export function fetchAddProductToCart(product, userId) {
  return {
    type: actions.ADD_PRODUCT_TO_CART,
    payload: { product, userId },
  };
}

export const removeArticleFromCart = (id, userId) => {
  return {
    type: actions.REMOVE_ARTICLE_FROM_CART,
    payload: { id, userId },
  };
};

export const loadCartStorage = (item) => {
  return {
    type: actions.LOAD_CARTSTORAGE,
    payload: item,
  };
};

export const loadCensorship = (bool) => {
  return {
    type: actions.FETCH_CENSORSHIP,
    payload: bool,
  };
};

export const clearCart = (userId) => {
  return {
    type: actions.CLEAR_CART,
    payload: userId,
  };
};

//------------------------------PRODUCT-----------------------------------------------------

export function getProduct() {
  const request = new Request(PRODUCTS);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchProduct(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getAllProduct() {
  const request = new Request(ALLPRODUCTS);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchAllProduct(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postProduct(body, navigate, multipart) {
  const request = new Request(PRODUCTS);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getProduct());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateProduct(body, id, navigate, multipart) {
  const request = new Request(`${PRODUCTS}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getProduct());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteProduct(id) {
  const request = new Request(`${PRODUCTS}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getProduct());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function likeProduct(id) {
  const request = new Request(`${LIKE_PRODUCT}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//------------------------------VOLUME-----------------------------------------------------

export function postVolume(body, multipart, navigate) {
  const request = new Request(VOLUME);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllVolumes());
        dispatch(getAllProduct());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getAllVolumes() {
  const request = new Request(`volumes`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchAllVolume(results));
    } catch (err) {
      if (err === "Vous êtes déconnecté.") {
        dispatch(fetchAdminDisconnected());
      }
      dispatch(fetchError(err));
    }
  };
}

export function getProductIdFromVolumeId(body, cb) {
  const request = new Request(`volumes/productsid`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.post(body);
      dispatch(fetchSuccess());
      cb(results);
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getVolumes(productId, setState) {
  const request = new Request(`volumes/${productId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      const sortResults = Array.from(results).sort(
        (a, b) => Number(a.volume) - Number(b.volume)
      );
      sortResults.forEach((vol) => {
        vol.chapters.sort(
          (a, b) => Number(a.chapterNumber) - Number(b.chapterNumber)
        );
      });

      setState(sortResults);
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getVolumesId(productId, setState) {
  const request = new Request(`volumes/volumesId/${productId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      const sortResults = Array.from(results).sort(
        (a, b) => Number(a.volume) - Number(b.volume)
      );
      setState(sortResults);
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getCurrentVolume(volumeId, setState) {
  const request = new Request(`volumes/current/${volumeId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      setState(results);
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getCurrentVolumeAdmin(volumeId, setState) {
  const request = new Request(`volumes/current/admin/${volumeId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      setState(results);
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateVolume(navigate, body, id, multipart) {
  const request = new Request(`${VOLUME}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllVolumes());
        dispatch(getAllProduct());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteVolume(productId, volumeId) {
  const request = new Request(`${VOLUME}${productId}/${volumeId}/`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllVolumes());
        dispatch(getAllProduct());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function saveVolumeProgress(body) {
  const request = new Request(`volumes/progress`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchUserConnected(successMess));
      dispatch(fetchSuccess(successMess.message));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//------------------------------CHAPTER-----------------------------------------------------

export function postChapter(body, id, navigate) {
  const request = new Request(`${CHAPTER}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllVolumes());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateChapter(body, id, navigate) {
  const request = new Request(`${CHAPTER}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteChapter(volumeId, chapterId, setState) {
  const request = new Request(`${CHAPTER}${volumeId}/${chapterId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllVolumes());
        dispatch(getCurrentVolume(volumeId, setState));
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//------------------------------CATEGORY-----------------------------------------------------

export function getCategory() {
  const request = new Request(CATEGORY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchCategory(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getUsersCategory() {
  const request = new Request(`${CATEGORY}usersCategory/`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchCategory(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postCategory(body, multipart, navigate) {
  const request = new Request(CATEGORY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCategory());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateCategory(body, id, multipart, navigate) {
  const request = new Request(`${CATEGORY}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCategory());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteCategory(id) {
  const request = new Request(`${CATEGORY}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCategory());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function addToCategory(body) {
  const request = new Request(CATEGORY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCategory());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//---------------------------------------TVA--------------------------------------------

export function getTva() {
  const request = new Request(TVA);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchTva(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postTva(body, navigate) {
  const request = new Request(TVA);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getTva());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateTva(body, id, navigate) {
  const request = new Request(`${TVA}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getTva());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteTva(id) {
  const request = new Request(`${TVA}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getTva());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//---------------------------------------NOTIF--------------------------------------------

export function getNotif(userId) {
  const request = new Request(NOTIFICATIONS);
  return async (dispatch) => {
    try {
      const results = await request.get();
      dispatch(fetchNotif(results, userId));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postNotif(body, multipart, navigate) {
  const request = new Request(NOTIFICATIONS);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getNotif());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateNotif(body, id, multipart, navigate) {
  const request = new Request(`${NOTIFICATIONS}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getNotif());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteNotif(id) {
  const request = new Request(`${NOTIFICATIONS}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getNotif());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//---------------------------------------AUTH ADMIN--------------------------------------------

export function getAllAdmins() {
  const request = new Request(ADMIN);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchAdmin(results));
      if (results.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function adminSignup(body, navigate) {
  const request = new Request(SIGNUP);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllAdmins());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function adminLogin(body) {
  const request = new Request(LOGIN);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchAdminConnected(successMess));
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function adminLogout() {
  const request = new Request(LOGOUT);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post();
      dispatch(fetchSuccess());
      dispatch(fetchAdminDisconnected());
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function adminVerify() {
  const request = new Request(VERIFY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      dispatch(fetchAdminConnected(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateAdmin(body, id, navigate) {
  const request = new Request(`${ADMIN}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllAdmins());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteAdmin(id) {
  const request = new Request(`${ADMIN}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllAdmins());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//---------------------------------------AUTH USER--------------------------------------------

export function getAllUsers() {
  const request = new Request(USER);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchUser(results));
      if (results.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function createUser(body, navigate) {
  const request = new Request(`${USER}create/`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllUsers());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function userSignup(body) {
  const request = new Request(USERSIGNUP);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function activateAccount(body) {
  const request = new Request(ACTIVATEACCOUNT);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function userLogin(body) {
  const request = new Request(USERLOGIN);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      if (successMess.userPseudo && successMess.userEmail) {
        let storeObject = {
          token: successMess.token,
        };
        if (storeObject.token) {
          dispatch(fetchUserConnected(successMess));
        }
      }
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function userLogout(dispatch, navigate) {
  dispatch(fetchUserDisconnected());
  navigate("/");
  dispatch(fetchMessage({ message: "Déconnexion." }));
}

export function forgotPassword(body) {
  const request = new Request(FORGOTPASSWORD);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function resetPassword(body) {
  const id = "reset-password";
  const request = new Request(`${RESETPASSWORD}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);

      dispatch(fetchSuccess());
      dispatch(
        fetchMessage(
          successMess === 401 ? { message: "erreur 401" } : successMess
        )
      );
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function userVerify() {
  const request = new Request(USERVERIFY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post();
      dispatch(fetchSuccess());
      if (successMess.redirection) {
        dispatch(fetchUserDisconnected());
        dispatch(loadCensorship(true));
        dispatch(fetchMessage(successMess));
      } else {
        dispatch(fetchUserConnected(successMess));
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateUser(body, id, navigate) {
  const request = new Request(`${USER}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllUsers());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteUser(id) {
  const request = new Request(`${USER}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getAllUsers());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postContactMessage(body) {
  const request = new Request(`${USER}contact`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchPopupMessage(successMess.message));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//-------------------------------------newsletter-------------------------------------------------
export function newsletterSouscription(body) {
  const request = new Request(`${USER}newsletter/create`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchPopupMessage(successMess.message));
      dispatch(userVerify());
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteFromNewsletter(identifier) {
  const request = new Request(`${USER}newsletter/delete/${identifier}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchPopupMessage(successMess.message));
      dispatch(userVerify());
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//-------------------------------------security---------------------------------------------------
export function getKey() {
  const request = new Request(KEY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchKey(results));
      if (results.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postKey(body) {
  const request = new Request(KEY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getKey());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteKey(chapterId) {
  const request = new Request(`${KEY}${chapterId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getKey());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postChapterImages(body, chapterId, multipart) {
  const request = new Request(`${KEY}chapterimages/${chapterId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getKey());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//---------------------------------------cart-----------------------------------------------------

export function addToCart(body, userId) {
  const request = new Request(`${CART}${userId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchPopupMessage(successMess.message));
      if (successMess.article) {
        dispatch(fetchAddProductToCart(successMess.article, userId));
      }
      if (successMess.redirection) {
        dispatch(fetchUserDisconnected());
        dispatch(fetchPopupMessage("Connectez vous !"));
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function itemToCart(body, userId) {
  const request = new Request(`${CART}shoppinglist/${userId}`);
  return async (dispatch) => {
    try {
      const result = await request.post(body);

      dispatch(loadCartStorage(result));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getByPurchaseDate(startDate, endDate, cb) {
  const request = new Request(`${CART}export/${startDate}/${endDate}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      cb(results);
    } catch (err) {
      if (err === "Vous êtes déconnecté.") {
        dispatch(fetchAdminDisconnected());
      }

      dispatch(fetchError(err));
    }
  };
}

export function getByCommandId(commandId, cb) {
  const request = new Request(`${CART}export/${commandId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      cb(results);
    } catch (err) {
      if (err === "Vous êtes déconnecté.") {
        dispatch(fetchAdminDisconnected());
      }

      dispatch(fetchError(err));
    }
  };
}

//---------------------------------------book-----------------------------------------------------

export function getChapters(volumeId, cb) {
  const request = new Request(`${BOOKS}chapters/${volumeId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.get();
      dispatch(fetchSuccess());
      cb(successMess);
      dispatch(fetchMessage(successMess));
    } catch (err) {
      dispatch(fetchMessage({ message: err }));
      dispatch(fetchError(err));
    }
  };
}

export function getInfoBook(url, cb) {
  const request = new Request(url);
  return async (dispatch) => {
    try {
      const successMess = await request.get();
      dispatch(fetchSuccess());
      cb(successMess);
    } catch (err) {
      if (err === "Vous êtes déconnecté.") {
        dispatch(fetchUserDisconnected());
      }
      dispatch(fetchError(err));
    }
  };
}

export function getExtract(url, cb) {
  const request = new Request(url);
  return async (dispatch) => {
    try {
      const successMess = await request.get();
      dispatch(fetchSuccess());
      cb(successMess);
    } catch (err) {
      dispatch(fetchMessage({ message: err }));
      dispatch(fetchError(err));
    }
  };
}

export function encryptBook(body) {
  const request = new Request(`${BOOKS}encrypt`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());

      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(fetchMessage(successMess));
        dispatch(getKey());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function removeLibrary(body) {
  const request = new Request(REMOVELIBRARY);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(fetchMessage(successMess));
        dispatch(getKey());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function getUserSoldBooks(userId, cb) {
  const request = new Request(`${BOOKS}soldbooks/${userId}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      cb(results);
      if (results.redirection) {
        dispatch(fetchUserDisconnected());
      }
    } catch (err) {
      if (err === "Vous êtes déconnecté.") {
        dispatch(fetchUserDisconnected());
        dispatch(fetchMessage({ message: err }));
      }

      dispatch(fetchError(err));
      dispatch(fetchSuccess());
    }
  };
}

//---------------------------------DOWNLOAD---------------------------------------------------

export function generateDownolad(body, cb) {
  const request = new Request(DOWNLOAD);
  return async (dispatch) => {
    dispatch(fetchPending());
    dispatch(
      fetchPopupMessage("Préparation du fichier, ne quittez pas la page !")
    );
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchPopupMessage(successMess.message));
      if (successMess.ready) {
        cb(successMess.ready);
      }
      if (successMess.redirection) {
        dispatch(fetchUserDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//---------------------------------NETWORK----------------------------------------------------

export function getNetwork() {
  const request = new Request(NETWORK);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const results = await request.get();
      dispatch(fetchSuccess());
      dispatch(fetchNetwork(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postNetwork(body) {
  const request = new Request(NETWORK);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      dispatch(getNetwork());
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function putNetwork(body, id) {
  const request = new Request(`${NETWORK}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      dispatch(getNetwork());
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteNetwork(id) {
  const request = new Request(`${NETWORK}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      dispatch(getNetwork());
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//----------------------------------CAROUSEL------------------------------------------------

export function getCarousel() {
  const request = new Request(CAROUSEL);
  return async (dispatch) => {
    try {
      const results = await request.get();
      dispatch(fetchCarousel(results));
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function postCarousel(body, multipart, navigate) {
  const request = new Request(CAROUSEL);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCarousel());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function updateCarousel(body, id, multipart, navigate) {
  const request = new Request(`${CAROUSEL}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.put(body, multipart);
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCarousel());
        navigate(-1);
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

export function deleteCarousel(id) {
  const request = new Request(`${CAROUSEL}${id}`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.delete();
      dispatch(fetchSuccess());
      dispatch(fetchMessage(successMess));
      if (successMess.redirection) {
        dispatch(fetchAdminDisconnected());
      } else {
        dispatch(getCarousel());
      }
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}

//------------------------------SHIPPING-----------------------------------------------

export function getFormToken(body, setState) {
  const request = new Request(`${CART}/getformtoken`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      dispatch(fetchSuccess());
      dispatch(fetchPopupMessage(successMess.message));
      if (successMess) {
        setState(successMess);
      }
      if (successMess.redirection) {
        dispatch(fetchUserDisconnected());
        dispatch(fetchPopupMessage("Connectez vous !"));
      }
    } catch (err) {
      dispatch(fetchPopupMessage(err.message));
      dispatch(fetchError(err));
    }
  };
}

export function submitPayment(body, KR, navigate) {
  const request = new Request(`payment/validatepayment`);
  return async (dispatch) => {
    dispatch(fetchPending());
    try {
      const successMess = await request.post(body);
      if (successMess.userId) {
        KR.hideForm("#paymentForm");
        dispatch(fetchUserConnected(successMess));
        dispatch(fetchSuccess());
        dispatch(fetchPopupMessage(successMess.message));
        navigate("/library");
        dispatch(clearCart(successMess.userId));
      }
    } catch (err) {
      dispatch(fetchPopupMessage(err.message));
      dispatch(fetchError(err));
    }
  };
}

//----------------------------------------------test-------------------------------------------

/*export function getImage() {
  const request = new Request(`test`);
  return async (dispatch) => {
    try {
      const results = await request.get();
      //dispatch(fetchCarousel(results));

      return results;
    } catch (err) {
      dispatch(fetchError(err));
    }
  };
}*/
//---------------------------------------------------------------------------------------------
