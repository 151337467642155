import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sticker from "../../../components/marketplace/Sticker";
import WrapperContainer from "../../../components/marketplace/WrapperContainer";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../components/marketplace/profil/Button";
import Card from "../../../components/marketplace/profil/Card";
import { passwordReinit } from "../../../components/marketplace/profil/controllers/buttonCtrl";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../../lib/state/actions";
import {
  getUserSoldBooks,
  deleteFromNewsletter,
  newsletterSouscription,
  //getImage,
} from "../../../lib/state/actions";
import { useUserState } from "../categoryPage/controllers/stateCtrl";
import { Helmet } from "react-helmet-async";

const Profil = () => {
  const { userConnected, userEmail, date, google, userId, newsletter } =
    useUserState(useSelector);

  const [soldInfo, setSoldInfo] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      dispatch(getUserSoldBooks(userId, setSoldInfo));
    }
  }, [userId, dispatch]);

  const desinscription = () => {
    dispatch(deleteFromNewsletter(userEmail));
  };

  const inscription = () => {
    const body = JSON.stringify({ userEmail });
    dispatch(newsletterSouscription(body));
  };

  return (
    <>
      <Helmet>
        <title>Gérez votre profil.</title>
        <meta name="robots" content="noindex"></meta>
        <meta
          name="description"
          content={`Déconnectez-vous, réinitialisez votre mot de passe,
          consultez vos achats.`}
        />
        <meta name="title" content={`Gérez votre profil.`} />
        <meta
          name="prerender-header"
          content="Location: http://www.mangaebook.com/profil"
        ></meta>
      </Helmet>
      <H1 className="segoesc">
        <a href="/">MangaeBook /</a>Mes informations
      </H1>
      <Sticker title="espace utilisateur" center={true} marginTop="60" />
      <WrapperContainer>
        <Center>
          <Box>
            <Span>
              <Button
                text="Déconnexion"
                validate={() => userLogout(dispatch, navigate)}
              />
            </Span>
          </Box>
          <Container>
            <Box>
              <Span className="montserratSemiBold hidden">Pseudo :</Span>
            </Box>
            <Box>
              <Span className="info montserrat">{userConnected}</Span>
            </Box>
            <Box>
              <Span className="montserratSemiBold hidden">E-mail :</Span>
            </Box>
            <Box>
              <Span className="info montserrat">{userEmail}</Span>
            </Box>
            <Box>
              <Span className="montserratSemiBold hidden">
                Inscrit depuis le :
              </Span>
            </Box>
            <Box>
              <Span className="info montserrat">{date}</Span>
            </Box>
            {google ? null : (
              <>
                <Box>
                  <Span className="montserratSemiBold hidden">
                    Mot de passe :
                  </Span>
                </Box>
                <Box>
                  <Span>
                    <Button
                      text="Réinitialiser"
                      validate={() =>
                        passwordReinit(dispatch, navigate, userEmail)
                      }
                    />
                    <Mention className="montserrat">* le mot de passe.</Mention>
                  </Span>
                </Box>
              </>
            )}
            <Checkbox>
              <label htmlFor="newsletter">
                <Span className="montserratSemiBold hidden">
                  Inscription à la newsletter :
                </Span>
              </label>
              <Box>
                <Cont>
                  <input
                    type="checkbox"
                    id="newsletter"
                    checked={newsletter}
                    onChange={() =>
                      newsletter ? desinscription() : inscription()
                    }
                  />
                  <label
                    htmlFor="newsletter"
                    bckcolor={newsletter.toString()}
                  ></label>
                </Cont>
              </Box>
            </Checkbox>
          </Container>
          <Box>
            <Span className="montserratSemiBold">Achats :</Span>
          </Box>
          <div>
            {soldInfo &&
              soldInfo.length > 0 &&
              soldInfo.map((info) => <Card key={info._id} info={info} />)}
          </div>
        </Center>
      </WrapperContainer>
    </>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 140px 140px;
  @media (max-width: 400px) {
    grid-template-columns: 0px 270px;
  }
`;

const H1 = styled.h1`
  position: absolute;
  top: 70px;
  left: 30px;
  font-size: 14px;
  @media (max-width: 1150px) {
    left: 10px;
  }
`;

const Center = styled.div`
  margin: 0 auto;
  width: 570px;
  @media (max-width: 600px) {
    width: 300px;
  }
`;

const Span = styled.span`
  color: ${(props) => props.theme.primary.black};
  &.info {
    color: ${(props) => props.theme.primary.offBlack};
  }
  &.hidden {
    @media (max-width: 400px) {
      display: none;
    }
  }
`;

const Box = styled.div`
  display: inline-grid;
  height: 40px;
  align-items: center;
`;

const Mention = styled.div`
  color: ${(props) => props.theme.primary.offBlack};
  margin-top: 5px;
  font-size: 12px;
  @media (min-width: 401px) {
    display: none;
  }
`;

const Checkbox = styled.div`
  box-sizing: border-box;
  width: 176px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.primary.black};
  @media (max-width: 400px) {
    margin-top: 35px;
  }
`;

const Cont = styled.div`
  & input {
    display: none;
    & + label {
      display: block;
      position: relative;
      width: 3em;
      height: 1.6em;

      border-radius: 1em;
      background: #f3f3f3;
      box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-transition: background 0.1s ease-in-out;
      transition: background 0.1s ease-in-out;
      &:before {
        content: "";
        display: block;
        width: 1.2em;
        height: 1.2em;
        border-radius: 1em;
        background: #fff;
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 0.2em;
        top: 0.2em;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
      }
      @media (max-width: 400px) {
        &:after {
          content: "Newsletter:";
          display: block;
          position: absolute;
          top: -17px;
        }
      }
    }
    &:checked {
      & + label {
        background: ${(props) =>
          props.bckcolor === "false" ? "#fff" : props.theme.secondary.color};
        transition: all 0.2s ease-in-out;
        &:before {
          box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
          left: 1.6em;
        }
      }
    }
  }
`;

export default Profil;
