import { getByPurchaseDate } from "../../../lib/state/actions";

export const ttcPrice = (tva, htPrice, tvaName) => {
  if (htPrice && tva) {
    const htPriceToInt = parseInt(htPrice);
    const searchTva = tva.find((tax) => tax.name === tvaName);
    if (searchTva) {
      const rate = Number(searchTva?.rate);
      if (rate) {
        let volumeTva = (htPrice * rate) / 100;
        let priceWithTaxe = Math.round(htPriceToInt + volumeTva).toFixed(2);
        if (priceWithTaxe && volumeTva) {
          return Number(priceWithTaxe / 100).toFixed(2);
        }
      }
    }
  }
};

export const sortByQuantity = (purchases, setState) => {
  let volumeArray = [];
  let chapterArray = [];
  for (let i = 0; i < purchases.length; i++) {
    if (purchases[i].volumeId && !purchases[i].chapterId) {
      //si achat volume
      const isExist = volumeArray.find(
        (obj) => obj.volumeId === purchases[i].volumeId
      );
      if (isExist) {
        volumeArray = [
          ...volumeArray.filter(
            (obj) => obj.volumeId !== purchases[i].volumeId
          ),
          {
            ...isExist,
            quantity: isExist.quantity + 1,
            price: isExist.price + purchases[i].price,
          },
        ];
      } else {
        volumeArray = [...volumeArray, { ...purchases[i], quantity: 1 }];
      }
    } else {
      const isExist = chapterArray.find(
        (obj) => obj.chapterId === purchases[i].chapterId
      );
      if (isExist) {
        chapterArray = [
          ...chapterArray.filter(
            (obj) => obj.chapterId !== purchases[i].chapterId
          ),
          {
            ...isExist,
            quantity: isExist.quantity + 1,
            price: isExist.price + purchases[i].price,
          },
        ];
      } else {
        chapterArray = [...chapterArray, { ...purchases[i], quantity: 1 }];
      }
    }
  }
  setState([...volumeArray, ...chapterArray]);
};

const dateToTimestamp = (inputDate) => {
  const [y, m, d] = inputDate.split(/-|\//);
  const date = new Date(y, m - 1, d);
  return date.getTime();
};

const endDateToTimestamp = (inputDate) => {
  const [y, m, d] = inputDate.split(/-|\//);
  const date = new Date(y, m - 1, d);
  const endDate = date.setDate(date.getDate() + 1);
  return endDate;
};

export const validateSearch = (startDate, endDate, dispatch, setState) => {
  const startTimeStamp = dateToTimestamp(startDate);
  const endTimeStamp = endDateToTimestamp(endDate);
  dispatch(getByPurchaseDate(startTimeStamp, endTimeStamp, setState));
};

export const sortByCommand = (purchase, setstate) => {
  let commands = [];
  const limitSearchDate = new Date("September 25, 2024 00:00:00");
  for (let i = 0; i < purchase.length; i++) {
    const isExist = commands.find(
      (command) => command.orderId === purchase[i]?.orderId
    );
    if (purchase[i].purchaseDate > limitSearchDate) {
      if (isExist) {
        commands = [
          ...commands.filter(
            (command) => command.orderId !== purchase[i]?.orderId
          ),
          {
            orderId: isExist.orderId,
            purchaseDate: isExist.purchaseDate,
            userId: isExist.userId,
            totalHt: isExist.totalHt + purchase[i]?.price,
            list: [
              ...isExist.list,
              {
                price: purchase[i]?.price,
                tva: purchase[i]?.tva,
                volumeId: purchase[i]?.volumeId ? purchase[i]?.volumeId : 0,
                chapterId: purchase[i]?.chapterId ? purchase[i]?.chapterId : 0,
              },
            ],
          },
        ];
      } else {
        commands.push({
          orderId: purchase[i]?.orderId,
          purchaseDate: purchase[i]?.purchaseDate,
          userId: purchase[i]?.userId,
          totalHt: purchase[i]?.price,
          list: [
            {
              price: purchase[i]?.price,
              tva: purchase[i]?.tva,
              volumeId: purchase[i]?.volumeId ? purchase[i]?.volumeId : 0,
              chapterId: purchase[i]?.chapterId ? purchase[i]?.chapterId : 0,
            },
          ],
        });
      }
    }
  }
  setstate(commands);
};

export const returnVolume = (volumeId, volumes) => {
  if (volumes) {
    const findVolume = volumes.find((vol) => vol._id === volumeId);
    if (findVolume) {
      return findVolume;
    }
  }
};

export const returnProduct = (volumeId, products, volumes) => {
  const vol = returnVolume(volumeId, volumes);
  if (vol) {
    const findProduct = products.find(
      (product) => product._id === vol.productId
    );
    if (findProduct) {
      return findProduct;
    }
  }
};

export const returnUserMail = (userId, users) => {
  if (users) {
    const isExist = users.find((user) => user._id === userId);
    if (isExist) {
      return isExist.email;
    }
  }
};

export const returnTotalttc = (command, tva) => {
  let total = 0;
  for (let i = 0; i < command.length; i++) {
    let commandPricettc = ttcPrice(tva, command[i].price, command[i].tva);
    total += Number(commandPricettc);
  }
  return total;
};
