import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "../../../components/marketplace/reader/Container";
import {
  getChapters,
  getInfoBook,
  getExtract,
  getVolumesId,
} from "../../../lib/state/actions";
import LittleSpinner from "../../../components/spinner/LittleSpinner";
import { useNavigate } from "react-router-dom";
import { localstorageCtrl } from "../../../lib/state/localStorage/localStorageCtrl";
import {
  useUserState,
  useProductsState,
} from "../categoryPage/controllers/stateCtrl";
import { Helmet } from "react-helmet-async";
import { REACT_APP_API_HOST } from "../../../service/index.js";
import { allReplace } from "../../../components/marketplace/reader/lib/readerCtrl.js";

const Liseuse = ({ extract, gift }) => {
  const { productId, id, chapterId } = useParams();
  const book = id;
  const surWrapRef = useRef();

  //user for bookmark localstorage key
  const { userId, userEbooks, userConnected } = useUserState(useSelector);
  const products = useProductsState(useSelector);

  const [myBook, setMyBook] = useState({});
  const [zoomFactor, setZoomFactor] = useState(1.0);
  const [chapters, setChapters] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [chapterIndex, setChapterIndex] = useState(0);

  const dispatch = useDispatch();
  const changeZoomFactor = (n) => setZoomFactor(n);
  const navigate = useNavigate();

  const findProductUrl = (productId) => {
    if (products && products.length > 0 && productId) {
      const selectProduct = products.find(
        (product) => product._id === productId
      );
      return selectProduct.imageURL.substring(1);
    }
  };

  const findPaperUrl = (productId) => {
    if (products && products.length > 0 && productId) {
      const selectProduct = products.find(
        (product) => product._id === productId
      );
      return selectProduct.paperURL;
    }
  };

  const findFrenchTitle = (productId) => {
    if (products && products.length > 0 && productId) {
      const selectProduct = products.find(
        (product) => product._id === productId
      );
      return allReplace(selectProduct.frenchTitle);
    }
  };

  const setChaptersWithSoldInfo = (response) => {
    if (userConnected && userEbooks /*&& userEbooks.length > 0*/) {
      const userSoldEbook =
        userEbooks.length > 0 && userEbooks.find((ebook) => ebook._id === book);
      if (userSoldEbook) {
        response.forEach((chapter) => {
          const chapterSoldByUser = userSoldEbook.chapters.find(
            (chap) => chap === chapter._id
          );
          if (chapterSoldByUser) {
            setChapters((oldState) => [
              ...oldState,
              { ...chapter, buy: true, gift: chapter.gift },
            ]);
          } else {
            setChapters((oldState) => [
              ...oldState,
              { ...chapter, buy: false, gift: chapter.gift },
            ]);
          }
        });
      } else {
        if (gift) {
          response.forEach((chapter) => {
            setChapters((oldState) => [
              ...oldState,
              { ...chapter, buy: false, gift: chapter.gift },
            ]);
          });
        }
        if (extract) {
          response.forEach((chapter) => {
            if (chapter._id === chapterId) {
              setChapters((oldState) => [
                ...oldState,
                { ...chapter, buy: true },
              ]);
            } else {
              setChapters((oldState) => [
                ...oldState,
                { ...chapter, buy: false },
              ]);
            }
          });
        }
      }
    } else {
      if (extract) {
        response.forEach((chapter) => {
          if (chapter._id === chapterId) {
            setChapters((oldState) => [...oldState, { ...chapter, buy: true }]);
          } else {
            setChapters((oldState) => [
              ...oldState,
              { ...chapter, buy: false },
            ]);
          }
        });
      }
    }
  };

  const setVolumesWithSoldInfo = (response) => {
    if (userEbooks && userEbooks.length > 0) {
      response.forEach((volume) => {
        const volumeIsSold = userEbooks.find(
          (userVolume) => userVolume._id === volume._id
        );
        if (volumeIsSold) {
          setVolumes((oldState) => [
            ...oldState,
            {
              volumeId: volume._id,
              chaptersPage: volume.chaptersPage,
              volumeNumber: volume.volume,
              buy: true,
            },
          ]);
        } else {
          setVolumes((oldState) => [
            ...oldState,
            {
              volumeId: volume._id,
              chaptersPage: volume.chaptersPage,
              volumeNumber: volume.volume,
              buy: false,
            },
          ]);
        }
      });
    } else {
      response.forEach((volume) => {
        setVolumes((oldState) => [
          ...oldState,
          { volumeId: volume._id, volumeNumber: volume.volume, buy: false },
        ]);
      });
    }
  };

  const nextVolumeExist = useCallback(() => {
    if (volumes && volumes.length > 0 && book) {
      const actualVolume = volumes.find((vol) => vol.volumeId === book);

      const nextVolume = volumes.find(
        (vol) => vol.volumeNumber === actualVolume.volumeNumber + 1
      );

      if (nextVolume) {
        return true;
      } else {
        return false;
      }
    }
  }, [book, volumes]);

  const goToNextVolume = useCallback(() => {
    if (volumes && volumes.length > 0) {
      const volume = volumes.find((vol) => vol.volumeId === book);
      const nextVolume = volumes.find(
        (vol) => vol.volumeNumber === volume.volumeNumber + 1
      );
      if (nextVolume && nextVolume.buy) {
        const userNextVolume = userEbooks.find(
          (ebook) => ebook._id === nextVolume.volumeId
        );
        if (userNextVolume) {
          return `/liseuse/${findFrenchTitle(productId)}/${productId}/${
            nextVolume.volumeId
          }/${userNextVolume.chapters[0]}`;
        } else {
          return `/item/${findFrenchTitle(productId)}/${productId}`;
        }
      } else {
        return `/item/${findFrenchTitle(productId)}/${productId}`;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, productId, userEbooks, volumes]);

  const nextChapter = useCallback(() => {
    //if not last chapter
    if (chapterIndex < chapters.length - 1) {
      const selectedChapter = chapters.find(
        (chapter) => chapter._id === chapters[chapterIndex + 1]._id
      );
      //if chapter is sold
      if (selectedChapter && selectedChapter.buy) {
        return {
          message: "Chapitre suivant",
          link: `/liseuse/${findFrenchTitle(productId)}/${productId}/${id}/${
            chapters[chapterIndex + 1]._id
          }`,
        };
        //if chapter not sold
      }
      if (selectedChapter && !selectedChapter.buy && selectedChapter.gift) {
        return {
          message: "Chapitre suivant",
          link: `/liseuse/${findFrenchTitle(productId)}/${productId}/${id}/${
            chapters[chapterIndex + 1]._id
          }/gift`,
        };
        //if chapter not sold
      } else {
        return {
          message: "Acheter le chapitre suivant",
          link: `/item/${findFrenchTitle(productId)}/${productId}/chapter`,
        };
      }
      //if last chapter
    } else {
      //if next volume exist
      if (nextVolumeExist()) {
        //clear bookmark storage
        localstorageCtrl("clearBookMark", id, userId);
        return {
          message: "Volume suivant",
          link: goToNextVolume(),
        };
        //if not next volume
      } else {
        //clear bookmark storage
        localstorageCtrl("clearBookMark", id, userId);
        return {
          message: "Fin du volume",
          link: `/item/${findFrenchTitle(productId)}/${productId}`,
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chapterIndex,
    chapters,
    goToNextVolume,
    id,
    nextVolumeExist,
    productId,
    userId,
  ]);

  const selectChapter = (chapterId) => {
    const selectedChapter = chapters.find(
      (chapter) => chapter._id === chapterId
    );
    if (selectedChapter && selectedChapter.buy) {
      navigate(
        `/liseuse/${findFrenchTitle(productId)}/${productId}/${id}/${chapterId}`
      );
    }
    if (selectedChapter && !selectedChapter.buy && selectedChapter.gift) {
      navigate(
        `/liseuse/${findFrenchTitle(
          productId
        )}/${productId}/${id}/${chapterId}/gift`
      );
    }
  };

  //bookMark storage
  /*useEffect(() => {
    if (id && userId && !gift && !extract) {
      //localstorageCtrl("updateBookMark", id, userId);
    }
  }, [id, userId, gift, extract]);*/

  //get volumeId & chapters for extract/gift, if user connected or not.
  useEffect(() => {
    if (book && productId) {
      setChapters([]);
      setVolumes([]);
      dispatch(getVolumesId(productId, setVolumesWithSoldInfo));
      dispatch(getChapters(book, setChaptersWithSoldInfo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, productId, /*userEbooks,*/ dispatch]);

  //if extract or gift
  useEffect(() => {
    if (book) {
      if (extract && chapters.length > 0) {
        dispatch(getExtract(`books/extract/${book}`, setMyBook));
      }
      if (gift) {
        if (userConnected) {
          if (chapters && chapterId) {
            const chapter = chapters.find((chap) => chap._id === chapterId);
            if (chapter && chapter.gift) {
              dispatch(
                getInfoBook(
                  `books/informations/${book}/${chapterId}`,
                  setMyBook
                )
              );
            }
            //redirection if gift url ans not a gift
            if (chapter && !chapter.gift) {
              navigate(`/item/${findFrenchTitle(productId)}/${productId}`);
            }
          }
        } else {
          navigate(`/item/${findFrenchTitle(productId)}/${productId}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    extract,
    gift,
    book,
    productId,
    chapters,
    chapterId,
    dispatch,
    navigate,
    userConnected,
  ]);

  //else
  useEffect(() => {
    if (book) {
      if (!extract && !gift) {
        if (
          userEbooks &&
          userEbooks.length > 0 &&
          chapters &&
          chapters.length > 0
        ) {
          const userSoldEbook = userEbooks.find((ebook) => ebook._id === book);
          if (userSoldEbook) {
            const isBuy = userSoldEbook.chapters.find(
              (chap) => chap === chapterId
            );
            if (isBuy) {
              dispatch(
                getInfoBook(
                  `books/informations/${book}/${chapterId}`,
                  setMyBook
                )
              );
            } else {
              navigate(`/item/${findFrenchTitle(productId)}/${productId}`);
            }
          } else {
            navigate(`/item/${findFrenchTitle(productId)}/${productId}`);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    extract,
    gift,
    book,
    productId,
    chapters,
    chapterId,
    dispatch,
    navigate,
    userConnected,
    userEbooks,
  ]);

  //redirection if extract url and no extract
  useEffect(() => {
    if (extract) {
      const firstChapter = chapters.find(
        (chapter) => chapter.chapterNumber === "1"
      );
      if (firstChapter) {
        if (firstChapter._id !== chapterId) {
          navigate(`/item/${findFrenchTitle(productId)}/${productId}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extract, chapters, productId, chapterId, navigate]);

  useEffect(() => {
    if (chapterId && chapterId !== "full" && chapters.length > 0) {
      const index = chapters.findIndex((el) => el._id === chapterId);
      if (index !== undefined) {
        setChapterIndex(index);
      }
    }
    if (chapterId && chapterId === "full" && chapters.length > 0) {
      setChapterIndex(0);
    }
  }, [chapterId, chapters]);

  //annule double touch / pinch
  const cancel = (e) => {
    if (e.touches.length === 2) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const wrapperEl = surWrapRef.current;
    wrapperEl.addEventListener("touchmove", cancel, {
      passive: false,
    });
    return () => {
      wrapperEl.removeEventListener("touchmove", cancel, {
        passive: false,
      });
    };
  });

  /* const disableSafariBack = (e) => {
    // is not near edge of view, exit
    if (e.pageY > 10 && e.pageY < window.innerHeight - 10) return;

    // prevent swipe to navigate gesture
    e.preventDefault();
  };

  useEffect(() => {
    let element = surWrapRef.current;
    element.addEventListener("touchstart", (e) => {
      disableSafariBack(e);
    });

    return () => {
      element.removeEventListener("touchstart", (e) => {
        disableSafariBack(e);
      });
    };
  });*/

  const returnCompleteVolumeChapters = () => {
    const currentVol = volumes?.find((vol) => vol.volumeId === book);
    if (currentVol && currentVol.chaptersPage?.length > 1) {
      return currentVol.chaptersPage;
    }
  };

  return (
    <Wrapper ref={surWrapRef}>
      <Helmet>
        <title>Lecture en ligne.</title>
        <meta name="robots" content="noindex"></meta>
        <meta name="description" content="Lisez en ligne." />
        <meta name="title" content={`Lecture en ligne.`} />
        <meta id="og-title" property="og:title" content={`Lecture en ligne.`} />
        <meta property="og:description" content="Lisez en ligne." />
        <meta
          property="og:url"
          content={`https://mangaebook.com/item/${findFrenchTitle(
            productId
          )}/${productId}`}
        />
        <meta
          id="og-image"
          property="og:image"
          content={`${REACT_APP_API_HOST}/${findProductUrl(productId)}`}
        />
        <meta
          property="og:image:secure_url"
          content={`${REACT_APP_API_HOST}/${findProductUrl(productId)}`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="419" />
      </Helmet>
      {myBook && chapters.length > 0 && chapterId && chapterIndex !== null ? (
        <Container
          book={book}
          paperURL={findPaperUrl(productId)}
          productId={productId}
          chapters={chapters}
          chapterId={chapterId}
          myBook={myBook}
          zoomFactor={zoomFactor}
          changeZoomFactor={changeZoomFactor}
          extract={extract}
          gift={gift}
          nextChapter={nextChapter}
          selectChapter={selectChapter}
          completeVolumeChapters={returnCompleteVolumeChapters()}
        />
      ) : (
        <LittleSpinner />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: static;
  min-height: 100%;
  min-width: 100%;
`;

export default Liseuse;
