import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "../../../components/admin/input/Text";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAdminsState } from "../../marketplace/categoryPage/controllers/stateCtrl";
import { validateAdmin } from "../../../lib/adminControler/validateAdmin/validate";
import Radio from "../../../components/admin/input/Radio";

const AdminUpdate = ({ title }) => {
  const { adminId } = useParams();
  const admin = useAdminsState(useSelector);
  const [inputValues, setInputValues] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (adminId && title === "modifier un administrateur") {
      const searchAdmin =
        admin && admin.length > 0 && admin.find((obj) => obj._id === adminId);
      if (searchAdmin) {
        setInputValues({
          email: searchAdmin.email,
          password: "",
          newPassword: "",
          newPasswordBis: "",
          pseudo: searchAdmin.pseudo,
          superAdmin: searchAdmin.superAdmin,
        });
      }
    } else {
      setInputValues({
        email: "",
        password: "",
        pseudo: "",
        superAdmin: false,
      });
    }
  }, [adminId, admin, title]);

  function handleClose() {
    navigate("/admin/admins");
  }
  const dispatch = useDispatch();

  const onChangeValue = (e) => {
    let name = e.target.name;
    setInputValues({ ...inputValues, [name]: e.target.value });
  };

  const onChangeBooleanValue = (e) => {
    let name = e.target.name;
    let val = e.target.value === "true" ? true : false;
    setInputValues({ ...inputValues, [name]: val });
  };

  return (
    <Wrapper>
      <>
        <H2>{title} :</H2>
        <Span onClick={handleClose}>page précédente</Span>
        <FormEl
          autocomplete="false"
          onSubmit={(e) =>
            validateAdmin(
              e,
              title,
              adminId,
              inputValues,
              admin,
              dispatch,
              navigate
            )
          }
        >
          <Text
            title="pseudo"
            name="pseudo"
            type="text"
            value={inputValues?.pseudo || ""}
            onchange={(e) => onChangeValue(e)}
          />
          <Text
            title="email"
            name="email"
            type="text"
            value={inputValues?.email || ""}
            onchange={(e) => onChangeValue(e)}
          />
          {!adminId ? (
            <>
              <Text
                title="mot de passe"
                name="password"
                type="text"
                value={inputValues?.password || ""}
                onchange={(e) => onChangeValue(e)}
              />
              <Radio
                legend="super administrateur"
                name="superAdmin"
                currentValue={inputValues?.superAdmin}
                onchange={(e) => onChangeBooleanValue(e)}
              />
            </>
          ) : (
            <>
              <Text
                title="ancien mot de passe"
                name="password"
                type="text"
                value={inputValues?.password || ""}
                onchange={(e) => onChangeValue(e)}
              />
              <Text
                title=" nouveau mot de passe"
                name="newPassword"
                type="text"
                value={inputValues?.newPassword || ""}
                onchange={(e) => onChangeValue(e)}
              />
              <Text
                title=" répéter nouveau mot de passe"
                name="newPasswordBis"
                type="text"
                value={inputValues?.newPasswordBis || ""}
                onchange={(e) => onChangeValue(e)}
              />
              <Radio
                legend="super administrateur"
                name="superAdmin"
                currentValue={inputValues?.superAdmin}
                onchange={(e) => onChangeBooleanValue(e)}
              />
            </>
          )}
          {<ValidateButton message={title} pad="1.5em" />}
        </FormEl>
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100dvh - 100px);
  z-index: 100;
  padding: 2em;
  }
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const Span = styled.span`
  position: absolute;
  padding: 7px;
  right: 0px;
  top: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export default AdminUpdate;
