import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Text from "../../../components/admin/input/Text";
import Radio from "../../../components/admin/input/Radio";
import ValidateButton from "../../../components/admin/button/ValidateButton";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { validateUser } from "../../../lib/adminControler/validateUser/validate";
import {
  useUsersState,
  useVolumesState,
  useProductsState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";
import UserBookCard from "./UserBookCard";
import { fetchMessage } from "../../../lib/state/actions";
import { selectedVolumeChapters } from "./userCtrl";

const UserUpdate = ({ title }) => {
  const [allowChanges, setAllowChanges] = useState(false);
  const [inputValues, setInputValues] = useState();

  //add new ebook to user's collection
  const [newBook, setNewBook] = useState();

  const { userId } = useParams();
  const products = useProductsState(useSelector);
  const users = useUsersState(useSelector);
  const volumes = useVolumesState(useSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //info to map book list
  const volumeInfos = (volumeId) => {
    if (volumes && volumes.length > 0 && products && products.length > 0) {
      const volumeObject = volumes.find((vol) => vol._id === volumeId);
      const productId = volumeObject?.productId;
      const productObject = products.find(
        (product) => product._id === productId
      );

      return `${productObject?.frenchTitle} vol. ${volumeObject?.volume}`;
    }
  };

  const addVolume = (id) => {
    if (inputValues.ebooks) {
      if (inputValues?.ebooks.length > 0) {
        const isExist = inputValues.ebooks.find((book) => book._id === id);
        if (isExist) {
          dispatch(
            fetchMessage({
              message: "Cet utilisateur possède déjà ce volume !",
            })
          );
        } else {
          setNewBook(id);
        }
      } else {
        setNewBook(id);
      }
    }
  };

  useEffect(() => {
    if (
      users &&
      users.length > 0 &&
      userId &&
      title === "modifier un utilisateur"
    ) {
      const searchUser = users.find((user) => user._id === userId);
      if (searchUser) {
        setInputValues({
          email: searchUser.email,
          password: "",
          newPassword: "",
          newPasswordBis: "",
          pseudo: searchUser.pseudo,
          ebooks: searchUser.ebooks,
          status: searchUser.status,
          googleAuth: searchUser.googleAuth,
          date: searchUser.date,
          newsletter:
            searchUser.newsletter !== undefined ? searchUser.newsletter : true,
        });
      }
    } else {
      setInputValues({
        email: "",
        password: "",
        pseudo: "",
        ebooks: [],
        status: true,
        googleAuth: false,
      });
    }
  }, [userId, users, title]);

  //autorize update
  const toggleAllowChanges = () => {
    setAllowChanges(!allowChanges);
  };

  function handleClose() {
    navigate("/admin/users");
  }

  const deleteBook = (volumeId) => {
    setInputValues({
      ...inputValues,
      ebooks: [...inputValues.ebooks.filter((ebook) => ebook._id !== volumeId)],
    });
  };

  const addBook = (volumeId) => {
    if (volumeId) {
      setInputValues({
        ...inputValues,
        ebooks: [
          ...inputValues.ebooks.filter((ebook) => ebook._id !== volumeId),
          { _id: volumeId, chapters: [] },
        ],
      });
    }
  };

  const addBookWithChapters = (volumeId) => {
    if (volumeId) {
      const chapters = selectedVolumeChapters(volumeId, volumes);
      if (chapters && chapters.length > 0) {
        setInputValues({
          ...inputValues,
          ebooks: [
            ...inputValues.ebooks.filter((ebook) => ebook._id !== volumeId),
            {
              _id: volumeId,
              purchaseDate: Date.now(),
              chapters: chapters.map((chap) => chap._id),
            },
          ],
        });
      }
    }
  };

  const deleteChapter = (volumeId, chapterId) => {
    const selectedBook = inputValues.ebooks.find(
      (ebook) => ebook._id === volumeId
    );
    setInputValues({
      ...inputValues,
      ebooks: [
        ...inputValues.ebooks.filter((ebook) => ebook._id !== volumeId),
        {
          _id: volumeId,
          chapters: [
            ...selectedBook.chapters.filter((chapter) => chapter !== chapterId),
          ],
        },
      ],
    });
  };

  const addChapter = (volumeId, chapterId) => {
    if (chapterId) {
      const selectedBook = inputValues.ebooks.find(
        (ebook) => ebook._id === volumeId
      );
      setInputValues({
        ...inputValues,
        ebooks: [
          ...inputValues.ebooks.filter((ebook) => ebook._id !== volumeId),
          {
            _id: volumeId,
            purchaseDate: Date.now(),
            chapters: [
              ...selectedBook.chapters.filter(
                (chapter) => chapter !== chapterId
              ),
              chapterId,
            ],
          },
        ],
      });
    }
  };

  const sortArrayObj = (array) => {
    if (array && array.length > 0) {
      const newArray = array.sort((a, b) => {
        let titleA = volumeInfos(a._id);
        let titleB = volumeInfos(b._id);
        if (titleA && titleB) {
          return titleA.localeCompare(titleB);
        }
        return array;
      });
      return newArray;
    }
  };

  return (
    <Wrapper>
      {!inputValues ? (
        <p>...loading</p>
      ) : (
        <>
          <Button type="button" onClick={() => toggleAllowChanges()}>
            autoriser les modifications.
          </Button>
          <H2>{title} :</H2>
          <Span onClick={handleClose}>page précédente</Span>
          Compte créé le {new Date(inputValues.date).toLocaleDateString("fr")}
          <FormEl
            action="/api/user"
            enctype="multipart/form-data"
            method={title === "créer un nouvel utilisateur" ? "post" : "put"}
            onSubmit={(e) =>
              validateUser(
                e,
                title,
                userId,
                inputValues,
                users,
                dispatch,
                navigate
              )
            }
          >
            <Text
              title="pseudo"
              name="pseudo"
              type="text"
              value={inputValues.pseudo}
              onchange={(e) =>
                setInputValues({
                  ...inputValues,
                  pseudo: e.target.value,
                })
              }
            />

            <Radio
              initialValue={inputValues.status}
              legend="Compte actif"
              name="active"
              currentValue={inputValues.status}
              onchange={() =>
                setInputValues({ ...inputValues, status: !inputValues.status })
              }
            />
            <Radio
              initialValue={inputValues.newsletter}
              legend="Newsletter"
              name="newsletter"
              currentValue={inputValues.newsletter}
              onchange={() =>
                setInputValues({
                  ...inputValues,
                  newsletter: !inputValues.newsletter,
                })
              }
            />
            <Radio
              initialValue={inputValues.googleAuth}
              legend="googleAuth"
              name="auth"
              currentValue={inputValues.googleAuth}
              onchange={() =>
                console.log(
                  "Le mode de création du compte utilisateur ne peut être modifiée."
                )
              }
            />
            <Text
              title="email"
              name="email"
              type="text"
              value={inputValues.email}
              onchange={(e) =>
                setInputValues({ ...inputValues, email: e.target.value })
              }
            />

            {!userId ? (
              <Text
                title="mot de passe"
                name="password"
                type="text"
                value={inputValues?.password || ""}
                onchange={(e) =>
                  setInputValues({ ...inputValues, password: e.target.value })
                }
              />
            ) : (
              <>
                <Text
                  title="ancien mot de passe"
                  name="password"
                  type="text"
                  value={inputValues?.password || ""}
                  onchange={(e) =>
                    setInputValues({ ...inputValues, password: e.target.value })
                  }
                />
                <Text
                  title=" nouveau mot de passe"
                  name="newPassword"
                  type="text"
                  value={inputValues?.newPassword || ""}
                  onchange={(e) =>
                    setInputValues({
                      ...inputValues,
                      newPassword: e.target.value,
                    })
                  }
                />
                <Text
                  title=" répéter nouveau mot de passe"
                  name="newPasswordBis"
                  type="text"
                  value={inputValues?.newPasswordBis || ""}
                  onchange={(e) =>
                    setInputValues({
                      ...inputValues,
                      newPasswordBis: e.target.value,
                    })
                  }
                />
              </>
            )}

            {allowChanges && (
              <Container>
                <label htmlFor="addBook">Ajouter un eBook :</label>
                <Select
                  name="addBook"
                  onChange={(e) => addVolume(e.target.value)}
                >
                  <option defaultValue="">choisir un ebook</option>

                  {volumes.map((volume) => (
                    <option key={volume._id} value={volume._id}>
                      {volumeInfos(volume._id)}
                    </option>
                  ))}
                </Select>
                <button
                  type="button"
                  style={{ marginRight: "10px" }}
                  onClick={() => addBookWithChapters(newBook)}
                >
                  ajouter avec tous les chapitres
                </button>
                <button type="button" onClick={() => addBook(newBook)}>
                  ajouter sans les chapitres
                </button>
              </Container>
            )}
            <div>
              <BigButton type="button" onClick={() => navigate(-1)}>
                Annuler
              </BigButton>
              <ValidateButton message={title} pad="1.5em" />
            </div>
            <H3>liste des ebooks :</H3>
            {inputValues &&
              inputValues.ebooks &&
              inputValues.ebooks.length > 0 &&
              sortArrayObj(inputValues.ebooks).map((vol) => (
                <UserBookCard
                  key={vol._id}
                  vol={vol}
                  deleteBook={deleteBook}
                  volumeInfos={volumeInfos}
                  deleteChapter={deleteChapter}
                  allowChanges={allowChanges}
                  userId={userId}
                  addChapter={addChapter}
                />
              ))}
          </FormEl>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`  
   display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100dvh - 100px);
  z-index: 100;
  padding: 2em;
  }
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const H2 = styled.h2`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

const H3 = styled.h3`
  margin: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  max-width: 500px;
`;

const Span = styled.span`
  position: absolute;
  padding: 7px;
  right: 0px;
  top: 100px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const Button = styled.button`
  margin: 20px 20px 0px 0px;
  align-self: end;
`;
const BigButton = styled.button`
  background-color: black;
  color: white;
  margin-right: 20px;
  padding: 16px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 5px;
`;

const Select = styled.select`
  width: 500px;
  margin: 0 10px;
`;

export default UserUpdate;
