import React from "react";
import styled from "styled-components";
import {
  cutString,
  firstToUpperCase,
} from "../../profil/controllers/shortTitleCtrl";
import theme from "../../../../theme";
import Heart from "../excluSlider/exclu/picto/Heart";
import { Link } from "react-router-dom";
import { swithColors } from "../../../../views/marketplace/categoryPage/controllers/themeCtrl";
import { calculLikes } from "../../../../views/marketplace/categoryPage/controllers/algoCtrl";
import censoredImage from "./image/18.png";
import { useSelector } from "react-redux";
import { useCensored } from "../../../../views/marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../../service";
import { allReplace } from "../../reader/lib/readerCtrl";

const ShopCard = ({ ratio, elementVisible, open, el }) => {
  const isCensored = useCensored(useSelector);

  return (
    <Card elementVisible={elementVisible} open={open} ratio={ratio}>
      {el && (
        <Link to={`/item/${allReplace(el.frenchTitle)}/${el._id}`}>
          <Container>
            <ImageContainer iscensored={isCensored.toString()}>
              {!isCensored && (
                <Img
                  iscensored={isCensored.toString()}
                  src={REACT_APP_API_HOST + el.imageURL}
                  alt="jaquette"
                />
              )}
            </ImageContainer>
            <Info>
              <div>
                <H4 className="montserratSemiBold">
                  {cutString(firstToUpperCase(el.frenchTitle), 40) + " "}
                </H4>
                <Span className="montserrat">de {el.author[0]}</Span>
              </div>
              <p>
                <Span className="montserrat strong">
                  {el.volumes.length === 1 && el.ended
                    ? "One-shot"
                    : el.volumes.length > 0 &&
                      `${el.volumes.length} tome${
                        el.volumes.length > 1 ? "s" : ""
                      }`}
                </Span>
                {
                  <Span className="montserrat">
                    {`${
                      el.ended
                        ? el.volumes.length > 1
                          ? "Série terminée"
                          : ""
                        : "Série en cours"
                    }`}
                  </Span>
                }
              </p>
              <Footer>
                <LikesContainer display={calculLikes(el)}>
                  <Heart small={true} />
                  <Like className="segoesc">{calculLikes(el)}</Like>
                </LikesContainer>
                {
                  <Cat
                    className="segoesc"
                    color={swithColors(el.category, theme)}
                  >
                    {el.category}
                  </Cat>
                }
              </Footer>
            </Info>
          </Container>
        </Link>
      )}
    </Card>
  );
};

const Card = styled.article`
  box-sizing: border-box;
  width: ${(props) =>
    props.open
      ? `${100 / props.elementVisible}%`
      : `${100 / props.elementVisible / props.ratio}%`};
  display: flex;
  justify-content: center;
  max-height: 374px;
  margin: ${(props) => (props.open ? "20px 0" : "none")};
  @media (max-width: 380px) {
    max-height: 310px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background: ${(props) =>
    props.iscensored === "true"
      ? `center no-repeat white url(${censoredImage})`
      : "unset"};
  background-size: ${(props) =>
    props.iscensored === "true" ? `70%` : "unset"};
  height: 241px;
  @media (max-width: 380px) {
    max-height: 190px;
  }
`;

const Img = styled.img`
  margin: 0 auto;
  display: block;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  text-align: center;
  box-sizing: border-box;
  height: 364px;
  width: 170px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.primary.offBlack};
  @media (max-width: 380px) {
    max-height: 313px;
    max-width: 136px;
  }
`;
//box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);

const Cat = styled.span`
  font-weight: bold;
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-size: 11px;
`;

const Info = styled.div`
  box-sizing: border-box;
  height: 123px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 380px) {
    padding-bottom: 0px;
    padding-left: 3px;
    padding-right: 3px;
  }
`;

const Span = styled.span`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.primary.offBlack};
  display: block;
  line-height: 18px;
  @media (max-width: 380px) {
    font-size: 14px;
  }
  &.strong {
    text-transform: uppercase;
  }
`;

const H4 = styled.h4`
  font-size: 14px;
  max-width: 100%;
  color: ${(props) => props.theme.primary.black};
  line-height: 16px;
  letter-spacing: 0.1px;

  @media (max-width: 380px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: flex-end;

  justify-content: space-between;
`;

const LikesContainer = styled.div`
  display: flex;
  opacity: ${(props) => (props.display > 0 ? 1 : 0)};
  align-items: center;
`;

const Like = styled.span`
  padding-left: 5px;
  font-size: 12px;
  font-weight: bold;
`;

export default ShopCard;
