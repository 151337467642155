import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Option from "../../../components/admin/input/optionInput/Option";
import Text from "../../../components/admin/input/Text";
import Textarea from "../../../components/admin/input/TextArea";
import Radio from "../../../components/admin/input/Radio";
import InputFile from "../../../components/admin/input/InputFile";
import styled from "styled-components";
import { volumeObject } from "../../../lib/adminControler/productObject";
import { validateVolume } from "../../../lib/adminControler/validateVolume/validate";
import { useParams, useNavigate } from "react-router-dom";
import { isbnIsExist } from "../../../lib/adminControler/isbnControler";
import { getCurrentVolumeAdmin } from "../../../lib/state/actions";
import AddFieldToCategory from "../../../components/admin/input/AddFieldToCategory";
import {
  useTvaState,
  useProductsState,
  useCategoryState,
  useVolumesState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";
import { REACT_APP_API_HOST } from "../../../service";
import { inputDate } from "../../../lib/adminControler/validateProduct/validate";

const VolumesUpdate = ({ title }) => {
  const products = useProductsState(useSelector);
  const volumes = useVolumesState(useSelector);
  const tva = useTvaState(useSelector);
  const category = useCategoryState(useSelector);

  const { productId } = useParams();
  const { volumeId } = useParams();
  const navigate = useNavigate();

  const book = () => {
    if (products && products.length > 0) {
      return products?.find((product) => product._id === productId);
    }
  };

  const categoryArray = () => {
    if (category) {
      const categoryAr = category?.find((cat) => cat.name === book()?.category);
      return categoryAr;
    }
  };

  const [newVolume, setNewVolume] = useState(volumeObject);
  const [soldByVolume, setSoldByVolume] = useState(false);
  const [sameIsbn, setSameIsbn] = useState(false);

  const dispatch = useDispatch();

  //verify if isbn already exist
  useEffect(() => {
    if (title === "créer un nouveau volume") {
      if (isbnIsExist(volumes, newVolume.ISBN)) {
        setSameIsbn(true);
      } else {
        setSameIsbn(false);
      }
    }
    if (title === "modifier le volume") {
      if (isbnIsExist(volumes, newVolume.ISBN)) {
        if (isbnIsExist(volumes, newVolume.ISBN) === newVolume._id) {
          setSameIsbn(false);
        } else {
          setSameIsbn(true);
        }
      } else {
        setSameIsbn(false);
      }
    }
  }, [volumes, newVolume.ISBN, title, dispatch, newVolume._id]);

  //setState currentVolume since volumeId
  useEffect(() => {
    if (title === "modifier le volume") {
      dispatch(getCurrentVolumeAdmin(volumeId, setNewVolume));
    }
  }, [title, dispatch, volumeId]);

  useEffect(() => {
    if (newVolume?.volumeSale && !newVolume?.chapterSale) {
      setSoldByVolume(true);
    } else {
      setSoldByVolume(false);
    }
  }, [newVolume]);

  const onChangeProperty = (e) => {
    let type = e.target.type;
    let name = e.target.name;

    if (type === "radio") {
      let bool = e.target.value === "true" ? true : false;
      setNewVolume({ ...newVolume, [name]: bool });
    }
    if (type === "number" || name === "price") {
      setNewVolume({
        ...newVolume,
        [name]: Number(e.target.value),
      });
    }
    if (type === "date") {
      setNewVolume({
        ...newVolume,
        [name]: e.target.value.toString(),
      });
    }
    if (
      name === "tags" ||
      name === "author" ||
      name === "chapters" ||
      name === "chaptersPage"
    ) {
      if (!newVolume[name].find((val) => val === e.target.value)) {
        setNewVolume({
          ...newVolume,
          [name]: [...newVolume[name], e.target.value],
        });
      }
    }

    if (
      type !== "radio" &&
      type !== "number" &&
      type !== "date" &&
      name !== "price" &&
      name !== "tags" &&
      name !== "chapters" &&
      name !== "author" &&
      name !== "chaptersPage"
    ) {
      setNewVolume({ ...newVolume, [name]: e.target.value });
    }
  };

  const addRandomLike = () => {
    let randomId =
      Math.floor(Math.random() * (999999999999999 - 111111111111111)) +
      111111111111111;
    setNewVolume({
      ...newVolume,
      likes: [...newVolume.likes, randomId.toString()],
    });
  };

  const pageArray = () => {
    let numbersArray = [];
    for (let i = 0; i <= 1000; i++) {
      numbersArray.push(i);
    }
    return numbersArray;
  };

  const deleteFromList = (item, string) => {
    setNewVolume({
      ...newVolume,
      [string]: [...newVolume[string].filter((el) => el !== item)],
    });
  };

  return (
    <>
      {((newVolume && productId && title !== "modifier le volume") ||
        (newVolume &&
          productId &&
          volumeId &&
          products &&
          category &&
          tva &&
          title === "modifier le volume")) && (
        <>
          <Wrapper
            action="/api/products/volume"
            enctype="multipart/form-data"
            method="put"
            onSubmit={(e) =>
              validateVolume(
                e,
                title,
                { ...newVolume, productId },
                dispatch,
                navigate
              )
            }
          >
            <H2>{title}</H2>
            {newVolume.lastModify && (
              <p>
                {`Ce volume a reçu un nouveau chapitre le
                ${new Date(newVolume.lastModify).toLocaleDateString("fr")}`}
              </p>
            )}
            <Text
              type="date"
              name="volumeReleaseDate"
              onchange={(e) => onChangeProperty(e)}
              title="Date de mise en vente du volume"
              value={newVolume.volumeReleaseDate}
            />
            <Radio
              legend="Produit actif"
              name="isActive"
              currentValue={newVolume?.isActive}
              onchange={onChangeProperty}
            />
            <Radio
              legend="Partage RS"
              name="share"
              currentValue={newVolume?.share}
              onchange={onChangeProperty}
            />
            <Radio
              legend="Téléchargement"
              name="download"
              currentValue={newVolume?.download}
              onchange={onChangeProperty}
            />
            {newVolume?.download && (
              <>
                <Textarea
                  name="right"
                  title="Copyright"
                  currentValue={newVolume?.right}
                  onchange={(e) => onChangeProperty(e)}
                />
                <Text
                  type="text"
                  name="bookId"
                  onchange={(e) => onChangeProperty(e)}
                  title="book uuid (inDesign)"
                  value={newVolume.bookId}
                />
              </>
            )}
            <Text
              type="number"
              name="volume"
              onchange={(e) => onChangeProperty(e)}
              title="volume / épisode ( '0' si 'One-shot')"
              value={newVolume?.volume}
            />
            {soldByVolume && (
              <P className="red">
                "Ce titre sera vendu UNIQUEMENT au volume !"
              </P>
            )}
            <Option
              name="readingDirection"
              title="sens de lecture"
              onchange={(e) => onChangeProperty(e)}
              array={["europe", "original", "webtoon"]}
              currentValue={newVolume?.readingDirection}
            />
            <Radio
              legend={`Vente par chapitre`}
              name="chapterSale"
              currentValue={newVolume?.chapterSale}
              onchange={onChangeProperty}
            />
            <Radio
              legend="Vente par volume"
              name="volumeSale"
              currentValue={newVolume?.volumeSale}
              onchange={onChangeProperty}
            />
            {newVolume?.chapterSale ? (
              <P className="red">Vente au chapitre autorisée</P>
            ) : (
              <P className="red">Pas de vente au chapitre.</P>
            )}
            {newVolume?.volumeSale ? (
              <P className="red">Vente au volume autorisée</P>
            ) : (
              <P className="red">Pas de vente au volume.</P>
            )}
            {
              <Option
                name="chaptersPage"
                title="page des chapitres"
                onchange={onChangeProperty}
                array={pageArray()}
                currentValue={newVolume?.chaptersPage}
                deleteFromList={deleteFromList}
              />
            }
            <Text
              type="date"
              name="originalReleaseDate"
              onchange={(e) => onChangeProperty(e)}
              title="Date de première publication"
              value={newVolume?.originalReleaseDate}
            />
            <Radio
              legend="Couleur"
              name="color"
              currentValue={newVolume?.color}
              onchange={onChangeProperty}
            />
            <Text
              type="number"
              name="totalOfPage"
              onchange={(e) => onChangeProperty(e)}
              title="Nombre de page"
              value={newVolume?.totalOfPage}
            />
            <p>
              {newVolume?.totalOfFile
                ? `Le dossier ${newVolume?._id} contient actuellement
        ${newVolume?.totalOfFile} fichiers. (couverture incluse)`
                : `N'oubliez pas d'enregistrer le dossier contenant les images puis modifiez le produit !`}
            </p>
            <Option
              name="traductor"
              title="Traducteur"
              onchange={(e) => onChangeProperty(e)}
              array={categoryArray()?.traductors}
              currentValue={newVolume?.traductor}
            />
            <AddFieldToCategory
              name="addTraductor"
              title="enregistrer un nouveau traducteur"
              categoryId={categoryArray()?._id}
              arrayToUpdate="traductors"
            />
            <Option
              name="agent"
              title="Agent"
              onchange={(e) => onChangeProperty(e)}
              array={categoryArray()?.agents}
              currentValue={newVolume?.agent}
            />
            <AddFieldToCategory
              name="addAgent"
              title="enregistrer un nouvel agent"
              categoryId={categoryArray()?._id}
              arrayToUpdate="agents"
            />
            <Radio
              legend="Excusivité numérique"
              name="digitalExclusive"
              currentValue={newVolume?.digitalExclusive}
              onchange={(e) => onChangeProperty(e)}
            />
            <InputFile
              name="cover"
              src={REACT_APP_API_HOST + newVolume?.imageURL}
              alt="jaquette"
            />
            <Option
              name="price"
              title="Prix"
              onchange={(e) => onChangeProperty(e)}
              array={categoryArray()?.prices}
              currentValue={newVolume?.price}
            />
            <AddFieldToCategory
              name="addPrice"
              title="enregistrer un nouveau prix"
              categoryId={categoryArray()?._id}
              arrayToUpdate="prices"
            />
            <Option
              name="tva"
              title="TVA"
              onchange={(e) => onChangeProperty(e)}
              array={tva}
              property="name"
              currentValue={newVolume?.tva}
            />
            <Text
              type="text"
              name="ISBN"
              onchange={(e) => onChangeProperty(e)}
              title="I-S-B-N"
              value={newVolume?.ISBN}
            />

            {sameIsbn && (
              <p style={{ color: "red", fontWeight: "bold" }}>
                ATTENTION: ISBN existant !!!
              </p>
            )}
            <Text
              type="text"
              name="likes"
              title="Likes"
              value={newVolume?.likes?.length}
            />
            <Likes>
              <P>Nombre de likes:</P>
              <Span>{newVolume?.likes?.length}</Span>
              <button type="button" onClick={addRandomLike}>
                ajouter un like
              </button>
            </Likes>

            <Text
              type="number"
              name="extract"
              onchange={(e) => onChangeProperty(e)}
              title="Extrait gratuit !!! --- (0 ou 10 pages min. / chiffre paire ) --- !!! "
              value={newVolume?.extract || 0}
            />
            {
              <Text
                type="date"
                name="freePeriod"
                onchange={(e) => onChangeProperty(e)}
                title="Volume gratuit jusqu'au "
                value={
                  newVolume?.freePeriod ||
                  inputDate(
                    new Date(new Date().setDate(new Date().getDate() - 1))
                  )
                }
              />
            }
            <Text
              type="number"
              name="royaltyBuyingPrice"
              onchange={(e) => onChangeProperty(e)}
              title="Royalty prix d'achat"
              value={newVolume?.royaltyBuyingPrice || 0}
            />
            <Text
              type="number"
              name="royaltyRevenu"
              onchange={(e) => onChangeProperty(e)}
              title="Royalty revenu"
              value={newVolume?.royaltyRevenu || 0}
            />
            {!sameIsbn && (
              <Button className="center" type="submit">
                {title}
              </Button>
            )}
            <Button type="button" onClick={() => navigate(-1)}>
              page précédente
            </Button>
          </Wrapper>
        </>
      )}
    </>
  );
};

const Wrapper = styled.form`
  background-color: lightblue;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 20px;
  z-index: 999;
`;

const P = styled.p`
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 5px;
  align-self: start;
  &.red {
    color: red;
  }
`;

const Span = styled.span`
  display: inline-block;
  padding: 15px;
  background-color: white;
  color: black;
  font-size: 18px;
  font-weight: bold;
  align-self: start;
  margin-bottom: 5px;
  margin-right: 10px;
`;

const Likes = styled.div``;

const Button = styled.button`
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
  margin-top: 20px;
  &.center {
    margin: 0 auto;
    border: solid red 3px;
  }
`;

const H2 = styled.h2`
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  padding: 30px 0px;
`;

export default VolumesUpdate;
