import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getByCommandId } from "../../../lib/state/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  returnProduct,
  returnVolume,
  returnUserMail,
  ttcPrice,
  returnTotalttc,
} from "./exportCtrl";
import {
  useProductsState,
  useVolumesState,
  useTvaState,
  useUsersState,
} from "../../marketplace/categoryPage/controllers/stateCtrl";

const CommandDetail = () => {
  const { commandId } = useParams();
  const dispatch = useDispatch();
  const products = useProductsState(useSelector);
  const volumes = useVolumesState(useSelector);
  const tva = useTvaState(useSelector);
  const users = useUsersState(useSelector);

  const [command, setCommand] = useState({});

  useEffect(() => {
    dispatch(getByCommandId(commandId, setCommand));
  }, [commandId, dispatch]);

  return (
    <Wrapper>
      <H1>Détail de la commande</H1>
      <Table>
        <Caption>
          commande de {returnUserMail(command[0]?.userId, users)} du{" "}
          {new Date(command[0]?.purchaseDate).toLocaleDateString("fr")} pour un
          total TTC de {returnTotalttc(command, tva)} €.
        </Caption>
        <thead>
          <tr>
            <Th scope="col">Titre fr.</Th>
            <Th scope="col">Volume</Th>
            <Th scope="col">Catégorie</Th>
            <Th scope="col">Prix HT</Th>
            <Th scope="col">Prix TTC</Th>
            <Th scope="col">ISBN</Th>
          </tr>
        </thead>
        {command &&
          command.length >= 1 &&
          command.map((art) => (
            <tbody key={art._id}>
              <tr>
                <Td>
                  {returnProduct(art.volumeId, products, volumes)?.frenchTitle}
                </Td>
                <Td>
                  {returnVolume(art.volumeId, volumes)?.volume === 0
                    ? "one-shot"
                    : returnVolume(art.volumeId, volumes)?.volume}
                </Td>
                <Td>
                  {returnProduct(art.volumeId, products, volumes)?.category}
                </Td>
                <Td>{art.price / 100}</Td>
                <Td>{ttcPrice(tva, art.price, art.tva)}</Td>
                <Td>{returnVolume(art.volumeId, volumes)?.ISBN}</Td>
              </tr>
            </tbody>
          ))}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  box-sizing: border-box;
  background-color: lightblue;
  min-height: calc(100vh - 100px);
  z-index: 100;
  padding: 2em;
  overflow: scroll;
`;

const H1 = styled.h1`
  margin: 20px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const Table = styled.table`
  min-width: 1820px;
  margin-top: 50px;
  height: 50px;
  border-collapse: collapse;
  border: 3px solid black;
  text-align: center;
`;

const Caption = styled.caption`
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 5px;
  text-align: center;
`;

const Th = styled.th`
  border: 1px solid black;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;

export default CommandDetail;
